import React from 'react';
import PropTypes from 'prop-types';
import cc from 'classcat';
import ReactGA from 'react-ga';
import { openInNewTab, templateString } from './player-helpers';
//import { usePlayerContext } from 'features/context/player-context-hooks';
//import { useCustomizations } from "@personicom/customizations";

const EVENT_CATEGORY = "ClickPlayerBranding";

const propTypes = {
    player: PropTypes.object,
    className: PropTypes.string,
};

//TODO: could this be handled by the theme rather than a custom style here?
const logoStyle = (logoUrl, brandingFlexBias) => {
    return {
        backgroundImage: `url(${logoUrl})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: "center",
        backgroundSize: "contain",
        margin: "0 2px",
        flexBasis: brandingFlexBias || "unset", //"8em" //
    };
};


export default class PlayerBranding extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(e, theUrl) {
        //TODO: Convert this to classless, use context/hooks.
        //const { videoProps } = usePlayerContext();
        //const customizations = useCustomizations();
        const customizations = this.props.customizations;
        const videoProps = this.props.videoProps;
        const buttonType = e.currentTarget.id || e.currentTarget.title || "PlayerBranding";

        const aiEvent = { buttonType: buttonType, buttonUrl: theUrl };
        const gaEvent = { category: EVENT_CATEGORY, action: buttonType, label: `${this.props.subdomain}:${videoProps.videoId}` };
        this.props.trackEvent(EVENT_CATEGORY, customizations, videoProps, aiEvent);
        ReactGA.event(gaEvent, ['pstracker']);
        
        openInNewTab(theUrl);
        //window.open(this.theUrl);
    }

    render() {
        const { playerProps, className, brandLogoUrl } = this.props;
        var templatedLogo = templateString(brandLogoUrl, this.props.playerProps, this.props.videoProps);
        const style = logoStyle(templatedLogo, playerProps.brandingFlexBias);

        const theUrl = templateString(this.props.brandUrl, this.props.playerProps, this.props.videoProps);

        return (
            <button
                ref={c => { this.button = c; }}
                className={cc([className, "video-react-control", "video-react-button"])}
                style={style}
                tabIndex="0"
                href={theUrl}
                onClick={e=>this.handleClick(e, theUrl)}
                alt={'Link To ' + this.props.brandName}
            />
        );
    }
}

PlayerBranding.propTypes = propTypes;


//Style for logos that aren't trimmed down, just in case for future use.
//   const standardStyle  = (logoUrl) => {
//       return {
//         backgroundImage: `url(${logoUrl})`,
//         backgroundRepeat: 'no-repeat',
//         backgroundPosition: "center",
//         backgroundSize: "cover",
//         width: "15%",
//         height: "70%",
//         display: 'block',
//         margin: '3px 0 0 8px',
//         maxWidth    : "120px",
//       };
//   };

