import React from 'react';
import _ from 'lodash';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useCustomizations } from "@personicom/customizations";
import { ThemeEx, FooterButtonProps, FooterRowConfig, IPlayerProps } from '../../player-types';
import LinkButton from "./link-button";
import DownloadButton from "./download-button";

interface FooterRowProps {
  playerProps: Partial<IPlayerProps>;
  row: FooterRowConfig;
};

const FooterRow = ({ playerProps, row }: FooterRowProps) => {
  const { images, blobUrl, ...rest } = useCustomizations();
  const classes = buildStyles({styles: (rest as any).styles});
  
  switch(row.type){
    case "text":
      return (
        <Grid container justifyContent="center" alignItems="center" className={classes.textFooterRow}>   {/*configid={row.id} configtype={row.type} */}
          {(row.text ?? "").split('\n').map((item, key) => {
            return <Typography className={classes.footerText} key={key}>{item} <br/></Typography>
          })}
        </Grid>
      );

    case "textlinks":
      return (
        <Grid container justifyContent="center" alignItems="center" className={classes.textLinkRow}>   {/*configid={row.id} configtype={row.type}  */}
          <Grid container justifyContent="center" alignItems="center" className={classes.textLinkRow}>          
            <Typography className={classes.textLinkList}>{row.title}</Typography>
          </Grid>
          <ul className={classes.textLinkList}>
            {_.map(row.buttons, btn => <LinkButton key={btn.id} playerProps={playerProps} buttonConfig={btn}/>)}
          </ul>
        </Grid>
      );

    case "social":
    case "actions":
      const styleKey = `${row.type}FooterRow`; //`
      const rowStyle = (classes as any)[styleKey];

      let configRowClasses = null;
      if (row.styles) {
        const buildConfigRowStyle = makeStyles(row.styles);
        configRowClasses = buildConfigRowStyle();
      }

      let titleElement = (
        <Grid container justifyContent="center">
          <Typography className={clsx(classes.footerText, configRowClasses && configRowClasses.socialTitleText)}>{row.title}</Typography>
        </Grid>
      );

      return (
        <Grid container className={clsx(classes.socialsRow,  configRowClasses && configRowClasses.socialsRow)}>  {/*configid={row.id} configtype={row.type}  */}
          {titleElement}
          <Grid container className={rowStyle}>            
            {_.map(row.buttons, btn => <FooterButton key={btn.id} playerProps={playerProps} buttonConfig={btn} />)}
          </Grid>
        </Grid>
      );

    case "footerLogo":
      const logo = blobUrl(images.footerLogo);
      return (
        <Grid container justifyContent="center" alignItems="center" className={classes.footerLogoRow}>
          <a href={row.url} target="_blank" rel="noreferrer">
            <img className={classes.footerLogoImage} src={logo} alt="footer logo"/>
          </a>         
        </Grid>
      );

    default:
      return null;
  }
};

export default FooterRow;

//====
// A simple control to choose which type of button to display
const FooterButton = ({ playerProps, buttonConfig }: FooterButtonProps ) => {
  return (
    <>
      {buttonConfig.type === "download-button" && <DownloadButton playerProps={playerProps} buttonConfig={buttonConfig} />}
      {buttonConfig.type !== "download-button" && <LinkButton playerProps={playerProps} buttonConfig={buttonConfig} />}
    </>
  )

}

const buildStyles = makeStyles((theme: ThemeEx) => ({
  footerLogoRow: {
    paddingBottom: 20
  },
  footerLogoImage: {},
  textFooterRow: {
    margin: theme.styles?.footerRow?.margin ?? 15,
  },
  footerText: {
    fontFamily: theme.styles?.footer?.fontFamily ?? "initial",
    fontSize: theme.styles?.footer?.fontSize ?? 24,
    fontWeight: theme.styles?.footer?.fontWeight ?? 700,
    textAlign: theme.styles?.footer?.textAlign ?? "initial",
    color: theme.styles?.footer?.color ?? theme.styles?.page?.color,
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
      textAlign: "center",
    },
  },
  socialsRow: {
    alignItems: "center",
    justifyContent: "center",
  },
  socialFooterRow: {  //Note: this style name is dynamically constructed, so searching for "socialFooterRow" yields no other results, but it IS used.
    width: "50%",
    margin: 15,
    justifyContent: theme.styles?.footer?.socialRow?.justifyContent ?? "space-evenly",
    alignItems: "center",
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    },
  },
  actionsFooterRow: { //Note: this style name is dynamically constructed, so searching for "actionFooterRow" yields no other results, but it IS used.
    margin: 0,
    justifyContent: "center",
  },
  textLinkRow: {
    margin: theme.styles?.footer?.textLinkRow?.margin ?? 15,
    fontSize: theme.styles?.footer?.textLinkRow?.fontSize ?? 14,
  },
  textLinkList: {
    listStyleType: theme.styles?.footer?.textLinkRow?.listStyle ?? "disc",
    display       : "flex",
    flexWrap      : "wrap",
    justifyContent: "center",
    maxWidth      : "775px",
    [theme.breakpoints.down("md")]  : {
      maxWidth    : "75%",
    },
    [theme.breakpoints.down("sm")]  : {
      maxWidth    : "100%",
      margin      : 0,
    },
    "& li"  : {
      "&:first-child"  : {
        display   : "flex",
      },
      color         : theme.styles?.footer?.color ?? theme.styles?.page?.color,
      marginRight   : 20,
      marginBottom  : theme.spacing(1),
      [theme.breakpoints.down("sm")]  : {
        textAlign   : "center",
        marginBottom: theme.spacing(1.5),
      },
      [theme.breakpoints.down("xs")]  : {
        display     : "flex",
      }
    },
    "& i": {
      color : theme.styles?.footer?.color ?? theme.styles?.page?.color,      
    }
  },
}));