import { useEffect, useState } from "react";
import cc from "classcat";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Link, Typography, useMediaQuery } from '@material-ui/core';
import { useCustomizations } from "@personicom/customizations";
import { ThemeEx } from './player-types';
import { getIsEmbeded } from "features/player/player-helpers";
import { mobileMQ } from "features/player/player-hooks";
import { openInNewTab, templateString } from 'features/player/player-helpers';
import { usePlayerContext } from "features/context/player-context-hooks";

interface ILogo {
  path: string | null;
  url: string | null;
}

const AppHeader = () => {
  const { videoProps, playerProps } = usePlayerContext();
  const isMobile = useMediaQuery(mobileMQ);
  const classes = buildStyles({ isMobile });
  const [logo, setLogo] = useState<ILogo | null>(null);

  const { contactCard, strings, links, flags, images, blobUrl } = useCustomizations();

  useEffect(() => {
    let path : string | null = images.dynamicLogo ?? images.logo ?? null;
    path = path ? templateString(path, playerProps!, videoProps) : null;
    if (path && !isValidHttpUrl(path)) path = blobUrl(path);
    
    var url = templateString(links.dynamicLogoUrl, playerProps!, videoProps);
    url = url ?? links.homeUrl;    
    
    //Figure out if anything actually changed
    if(logo && logo.path === path && logo.url === url) return;  //nothing changed

    console.log("Logo Click URL:",  url);
    setLogo({
      path,
      url,
    });

  }, [logo, links, playerProps, videoProps, blobUrl, images]);

  if (getIsEmbeded()) return null; //Don't display the header if we're inside an iframe
  
  const contactImageUrl : string | null = contactCard ? templateString(contactCard.contactImageUrl, playerProps, videoProps) : null;

  function onClick(e: any, url:string|undefined){    
    openInNewTab(url);
    e.preventDefault();
  }



  return (
    <Grid id="app-header" item xs={12} sm container className={classes.headerRoot}  >
      <Grid item xs container direction="row" spacing={2} >
        <Grid item xs container justifyContent={flags.centerLogo ? "center" : "flex-start"}>
          {logo &&
            <a href={logo.url!} onClick={ (e) => onClick(e, logo.url!)} target="_blank" rel="noreferrer">
              <img className={cc({ "img-responsive": flags.responsiveLogo, [classes.logoImage]: true })} src={logo.path!} alt={strings.logoAlt} />
            </a>
          }
        </Grid>
        {contactCard &&
          <Grid item xs container justifyContent="flex-end">
            {contactImageUrl && contactImageUrl.trim().length &&
              <Grid item xs container justifyContent="flex-end">
                <img className={cc({ "img-responsive": flags.responsiveLogo, [classes.logoImage]: true })} src={contactImageUrl} alt="Headshot"/>
              </Grid>
            }
            <Grid>
              <ul className={classes.headerContactCardInfoContainer}>
                {contactCard.contactRows.map(contactRow => <Typography className={clsx(classes.headerContactCardLineItems, contactRow.style && contactRow.style === 'bold' ? classes.headerContactCardInfoBoldStyle : null )} key={contactRow.id}>{templateString(contactRow.label, playerProps, videoProps)}</Typography>)}
              </ul>
            </Grid>
          </Grid>
        }
        {strings.hasOwnProperty('headerInfoText') &&
        <Grid container justifyContent="center">
            {strings.hasOwnProperty('headerInfoUrl') &&
              <Link href={strings.headerInfoUrl} className={classes.headerInfoText} target="_blank">{strings.headerInfoText}</Link >
            }
            { !strings.hasOwnProperty('headerInfoUrl') &&
              <Typography className={clsx(classes.headerInfoText)}>{strings.headerInfoText}</Typography>
            }
        </Grid>
        }
      </Grid>
    </Grid >
  );
}

function isValidHttpUrl(string:string) {
  let url;
  
  try {
    url = new URL(string);
  } catch (_) {
    return false;  
  }

  return url.protocol === "http:" || url.protocol === "https:";
}

export default AppHeader;

const buildStyles = makeStyles((theme: ThemeEx) => ({
  headerRoot: {
    padding: "20px 40px 16px 40px",
    backgroundColor: `${theme.styles?.header?.backgroundColor ?? theme.styles?.page?.backgroundColor}`,
    borderBottom: theme.styles?.header?.borderBottom ?? `${theme.styles?.header?.borderBottomWidth ?? "5px"} solid ${theme.styles?.page?.borderColor}`, //`
    [theme.breakpoints.down('xs')]: {
      alignItems: "center",
      justifyContent: "center",
      overflowX: "hidden",
      padding: "20px 10px 16px 10px",
    },
  },
  headerInfoText: {
    fontSize: 24,
    fontWeight: 700,
    color: theme.styles?.header?.color ?? theme.styles?.page?.color,
    display: "flex",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down('xs')]: {
      fontSize: 20
    },
  },
  headerContactCardInfoContainer:{
    padding: "0px 0px 0px 5px",
    margin: "0px 0px 0px 0px",    
  },
  headerContactCardInfoBoldStyle:{
    fontWeight: 700
  },  
  headerContactCardLineItems: {
    fontSize: `${theme.styles?.contactCard?.fontSize}`,
    fontFamily: `${theme.styles?.contactCard?.fontFamily}`,
    color: theme.styles?.contactCard?.color ?? theme.styles?.page?.color,
    lineHeight: theme.styles?.contactCard?.lineHeight,
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
      textAlign: "left",
    },
  },
  logoImage: (props: any) => ({
    height: `${theme.styles?.header?.logo?.height ?? "70px"}`, //`
    maxHeight: `${theme.styles?.header?.logo?.maxHeight ?? "none"}`, //`
    marginLeft: 15,
    [theme.breakpoints.down('xs')]: {
      height: props.isMobile ? "auto" : 55,
      width: props.isMobile ? "100%" : "unset",
      marginLeft: 0,
    }
  })
}));