import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { LoadingIndicator } from "@personicom/customizations";
import PlayerView from "./features/player/player-view";
import AppFooter from "./app-footer";
import { getIsEmbeded } from "features/player/player-helpers";
import { usePlayerContext } from "features/context/player-context-hooks";
// import { AuthProvider } from "features/authentication/auth-provider";

const buildStyles = makeStyles(theme => ({
  appBody: (props: any) => ({
    padding: props.isEmbeded ? "0" : "21px",
    flexWrap: "nowrap",
    textAlign: "center",
    "& > div": {
      width: "100%",
    },
    [theme.breakpoints.down(400)]: {
      padding: props.isEmbeded ? "0" : "21px 4px",
    }
  }),
}));

const AppBody = () => {
  const { videoProps, playerProps } = usePlayerContext();
  const classes = buildStyles({ isEmbeded: getIsEmbeded() });

  return (
    <>
      {(!videoProps) && <LoadingIndicator  message="initializing app..."/> }
      {videoProps && (
        <>
          {/* <AuthProvider> */}
            <Grid id="app-body" container justifyContent="center" className={classes.appBody}>
              <PlayerView playerProps={playerProps!} />
            </Grid>
            <AppFooter playerProps={playerProps!} />
          {/* </AuthProvider> */}
        </>
      )}
    </>
  );
};

export default AppBody;
