import { ReactNode } from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { LabelPosition } from "./form-types";

export interface IFormFieldWrapperProps {
  labelPosition?: LabelPosition;
  label: string;
  labelClasses?: Record<string, any>;
  children?: ReactNode;
}

//Turns the labelClasses on the field into a class we can use
const buildStyles = makeStyles((theme: any) => ({
  labelLeft: (props: any) => {
    const style = {
      ...props,
    };
    return style;
  },
  labelTop: (props: any) => {
    const style = {
      marginBottom: "0.25em",
      ...props,
    };
    return style;
  },
  row: {
    marginBottom: "2em",
  }  
}));

const FormFieldWrapper = ({labelPosition, label, labelClasses, children}: IFormFieldWrapperProps) => {

  //just show the children for no label position, or something other than horiz/vert (e.g. inline)
  if(!children || !labelPosition || labelPosition === LabelPosition.inline) return <>{children}</>;

  const classes = buildStyles({...labelClasses});

  return (
    <>
      {/* left label position */}      
      { labelPosition === LabelPosition.left && (
        <Grid container className={classes.row}>
          <Grid item xs={12} sm={3} container alignContent="flex-end" justifyContent="flex-start">
            <Typography className={classes.labelLeft}>
              {label}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={9} container alignContent="flex-end" justifyContent="flex-start">
            {children}
          </Grid>
        </Grid>
      )}
      
      {/* Top label position, organize as a stack */}
      { labelPosition === LabelPosition.top && (
        <Grid container direction="column" wrap="nowrap">
          <Grid item xs={12}>
            <Typography className={classes.labelTop}>
              {label}
            </Typography>  
          </Grid>
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default FormFieldWrapper;