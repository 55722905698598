import React from 'react';
import cc from 'classcat';
import ReactGA from 'react-ga';
import { trackEvent, createUrl, useDownloadProperties, useVideoDownload } from "./player-helpers";
import { useCustomizations } from '@personicom/customizations';
import { usePlayerContext } from 'features/context/player-context-hooks';
import { useTrackPlayerEvent } from 'features/appinsights-provider';

//TODO: change the envent to be embeded button click or something?
const EVENT_CATEGORY = "ClickLandingPageButton";

const ControlBarButton = React.forwardRef(({player, className, buttonProps, videoUrl, playerProps,videoProps, onClick}, ref) => {

  if(buttonProps.type === "link"){
    return <ControlBarLink ref={ref} config={buttonProps} playerProps={playerProps} videoProps={videoProps} className={className} />
  }
  else if(buttonProps.type === "download-button"){
    return <ControlBarDownloadButton ref={ref} config={buttonProps} playerProps={playerProps} videoProps={videoProps} videoUrl={videoUrl} className={className} />
  }
  else if(buttonProps.type === "share-button"){
    return <ControlBarShareButton ref={ref} onClick={onClick} config={buttonProps} playerProps={playerProps} videoProps={videoProps} className={className} />
  }
});

export default ControlBarButton;

const ControlBarLink = React.forwardRef(({renderId, player, className, config, playerProps, videoProps}, ref) => {
  const url   = createUrl(config.url, null, playerProps, videoProps);
  const customizations = useCustomizations();
  const trackEvent = useTrackPlayerEvent();
  const subdomain = customizations.subdomain;

  function onClick(e){
    const buttonType = e.currentTarget.id || e.currentTarget.title || "ControlBarLinkButton";
    const { videoId }  = playerProps;
    const aiEvent         = { buttonType: buttonType, buttonUrl: e.currentTarget.href };
    const gaEvent         = { category: EVENT_CATEGORY, action: buttonType, label: `${subdomain}:${videoId}` };

    trackEvent(EVENT_CATEGORY, customizations, videoProps, aiEvent);
    ReactGA.event(gaEvent, ['pstracker']);
  }

  return (
    <a id={config.id} ref={ref} onClick={onClick} className={cc([className, "video-react-control", "video-react-button"])} style={config.style} title={config.tip} tabIndex="0" href={url} target="_blank" rel="noreferrer">
      <i className={config.icon}/>
    </a>
  );
});

const ControlBarDownloadButton = React.forwardRef(({renderId, player, className, config, playerProps, videoUrl}, ref) => {
  const { videoProps } = usePlayerContext();
  const customizations = useCustomizations();
  const { subdomain } = useCustomizations();
  const urlInfo     = useDownloadProperties(subdomain, playerProps, videoUrl);
  const [onDownload, isDownloading]  = useVideoDownload(urlInfo);
  const trackEvent = useTrackPlayerEvent();
  
  function onClick(e){
    if (!isDownloading)
    {
      onDownload(e);

      const buttonType = e.currentTarget.id || e.currentTarget.title || "ControlBarDownloadButton";
      const { videoId }  = playerProps;
      const aiEvent         = { buttonType: buttonType, buttonUrl: e.currentTarget.href };
      const gaEvent         = { category: EVENT_CATEGORY, action: buttonType, label: `${subdomain}:${videoId}` };

      trackEvent(EVENT_CATEGORY, customizations, videoProps, aiEvent);
      ReactGA.event(gaEvent, ['pstracker']);
    }
  }

  return (
    <button id={config.id} ref={ref} disabled={isDownloading} onClick={onClick} className={cc([className, "video-react-control", "video-react-button"])} style={config.style} title={config.tip} tabIndex="0">
      <i className={config.icon}/>
    </button>
  );
});

const ControlBarShareButton = React.forwardRef(({renderId, player, className, config, playerProps, onClick}, ref) => {
  return (
    <button id={config.id} ref={ref} onClick={onClick} className={cc([className, "video-react-control", "video-react-button"])} style={config.style} title={config.tip} tabIndex="0">
      <i className={config.icon}/>
    </button>
  );
});