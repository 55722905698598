import { CSSProperties } from "react";


//TODO: add support for all types - right now, only supporting "string" and "date";
export type FieldTypes = "string" | "json" | "image" | "email" | "phone" | "link" | "select" | "captcha" | "hidden" | "file" | "date" | "label" | "row";
export type Alignments = "start" | "center" | "end";
// export type LabelPositions = "top" | "left" | "inline";  //default = inline (material ui, within the element)
export enum LabelPosition {
  left = "left",
  top = "top",
  inline = "inline",
};

//An option for a select field
export interface FormFieldOption {
  id: string,
  label: string,
  value: string,
};

export interface FormDateOption {
  dateType: "month" | "day" | "year";
  minYear?: number;   //defaults to 2000 if not provided
  maxYear?: number;   //default to the current year, if not provided
}

export interface FormValueConverter {
  dataType: "date";   //TODO: add support for other conversions, as necessary
  template?: string;
}

//Layout properties for an item within a grid layout
export interface IGridItemLayout {
  area?: string;
  gridColumn?: string;
  justify?: Alignments;
  align?: Alignments;
}

//Base interface for layout and style props of a component
export interface IComponentLayout {
  grid?: IGridItemLayout,
  flex?: CSSProperties;  
  mobile?: CSSProperties;
  props?: Record<string, any>;    //properties that are {...spread} on the relevant control (e.g. captcha)
  style?: Record<string, any>;    //styles appiled to the "style" prop of the relevant control (e.g. image)
};


//Represents an input field to capture data in the form
export interface IFormField extends IComponentLayout {
  type: FieldTypes;
  id: string;
  name?: string;
  label: string;
  labelPosition?: LabelPosition;
  labelClasses?: Record<string, any>; 
  placeholder?: string;
  isRequired?: boolean;  
  options?: string[] | FormFieldOption[] | FormDateOption;   //if field type is "select", this will be the options in the select box
  componentProps?: Record<string, any>;
  componentClasses?: Record<string, any>;
  url?: string;   //if this is a link type field, this is the url
  children?: IFormField[];   //children elements for interpolating other components into a form. e.g. a link into a label field, or a row of multiple components.
  valueConverter?: FormValueConverter;
  // options?: AppFieldOption[] | string[];
  // propertyName?: string;  //the name to use when sending the value to the server. Will override the fieldId (which is the default) if present. Will take first non-null value of multiple fields with the same propertyName
};


export interface IFormAction extends IComponentLayout {
  id: string;
  type?: "submit" | "button"; //default: submit
  label: string;
  size?: "small" | "medium" | "large"; //default: medium
  variant?: "text" | "outlined" | "contained"; //default: outlined
  isSubmit?: boolean;
  submitPath?: string;      //The api path to "post" when the button has been pressed
  componentProps?: Record<string, any>;
};

export interface IFormLayout extends IComponentLayout {
  container?: CSSProperties;//css overrides for the container that contains the two items below
  actions?: CSSProperties;  //css overrides for the Actions container

  //TODO: support flex layout?
}

export interface IFormLink {
  id: string;
  label: string;
  url: string;
}

export interface IFormProps {
  title: string;
  layout: IFormLayout;
  fields: IFormField[];
  actions: IFormAction[];
  links: IFormLink[];
}