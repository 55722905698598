import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ReactGA from 'react-ga';
import { useCustomizations } from "@personicom/customizations";
import { ThemeEx, FooterButtonProps, IPlayerProps } from '../../player-types';
import { openInNewTab, createUrl, templateString } from 'features/player/player-helpers';
import { usePlayerContext } from 'features/context/player-context-hooks';
import { useTrackPlayerEvent } from 'features/appinsights-provider';

const EVENT_CATEGORY = "ClickLandingPageButton";

const LinkButton = ({ playerProps, buttonConfig }: FooterButtonProps) => {
  const customizations = useCustomizations();
  const trackEvent = useTrackPlayerEvent();
  const { flags, images, blobUrl} = useCustomizations();
  const { videoProps } = usePlayerContext();
  const classes = buildStyles();
   
  function onClick(e: any) {
    const buttonType = e.currentTarget.id || e.currentTarget.title || "LinkButton";

    //TODO: validate the SID == videoId
    const aiEvent = { buttonType: buttonType, buttonUrl: e.currentTarget.href };
    const gaEvent = { category: EVENT_CATEGORY, action: buttonConfig.id, label: `${playerProps.subdomain}:${videoProps?.videoAk ?? videoProps?.videoId}` };

    trackEvent(EVENT_CATEGORY, customizations, videoProps, aiEvent);
    ReactGA.event(gaEvent, ['pstracker']);

    console.log("Footer click:", e.currentTarget.href);
    openInNewTab(e.currentTarget.href);
    e.preventDefault();
  }
    
  let url     = createUrl(buttonConfig.url, null, playerProps as IPlayerProps, videoProps!);
  const style   = styleMap(buttonConfig.type, classes);
  const templatedTip = templateString(buttonConfig.tip ?? '', playerProps, videoProps);
  const templatedLabel = templateString(buttonConfig.label ?? '', playerProps, videoProps);
  
  //Add scheme if missing  
  if (!/^([a-z][a-z0-9+\-.]*):/i.test(url)) {
    url = 'http://' + url;
  }
  

  let configButtonClasses = null;
  if (buttonConfig.styles) {
    const buildConfigButtonStyle = makeStyles(buttonConfig.styles);
    configButtonClasses = buildConfigButtonStyle();
  }

  if (buttonConfig.type === "textlink") {
    return (
      <li>
        <a className={style} href={url} onClick={onClick} target="_blank" title={templatedTip} rel="noreferrer">
          {buttonConfig.icon && <i className={clsx(buttonConfig.icon, classes.buttonIcon)}></i>}
          {templatedLabel}
        </a>
      </li>
    )
  } else if (buttonConfig.type === "textItem") {
    return (
      <li>
        {buttonConfig.icon && <i className={clsx(buttonConfig.icon, flags.faShareButtonSizeOverride, classes.buttonIcon)}></i>}
        {templatedLabel}
      </li>

    )
  } else {
    var styleOverride = null;
    if (buttonConfig.backgroundImage) {
      const backgroundImage = blobUrl(images.buttonBackgroundImage);
      styleOverride = {
        background: `url(${backgroundImage})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        backgroundPosition: "center"
      };
    }

    if (buttonConfig.buttonStyleOverride) {
      if (styleOverride !== null) {
        styleOverride = Object.assign(styleOverride, buttonConfig.buttonStyleOverride);
      } else {
        styleOverride = buttonConfig.buttonStyleOverride;
      }
    }

    const linkStyle = buttonConfig.linkStyleOverride ? buttonConfig.linkStyleOverride : {textDecoration: "none"};

    return (
      //Socials & Actions
      <a href={url} onClick={onClick} target="_blank" title={templatedTip} style={linkStyle} rel="noreferrer">
        <div className={clsx(style, buttonConfig.icon && classes.buttonIconWithText)} style={styleOverride}>
          {buttonConfig.icon && <i className={clsx(buttonConfig.icon, flags.faShareButtonSizeOverride, classes.buttonIcon)}></i>}
          {templatedLabel && <Typography style={styleOverride} className={clsx(classes.buttonLabel, configButtonClasses && configButtonClasses.buttonStyles)}>{templatedLabel}</Typography>}
        </div>
      </a>
      )
  }
};

export default LinkButton;


const styleMap = (type: string, classes: any) => {
  switch(type.toLowerCase()){
    case "social"   : return classes.socialButton;
    case "link": return classes.socialButton;
    case "textlink": return classes.textLink;
    case "text": return classes.text;
    case "download-button"  : return classes.socialButton;
    case "action"   : return classes.actionButton;
  }
};

const buildStyles = makeStyles((theme: ThemeEx) => ({
  textLink: {
    fontSize: theme.styles?.footer?.fontSize ?? 12,
    color: theme.styles?.footer?.color ?? theme.styles?.page?.color,
    textDecoration: "none",
    display: "inline-block",
    backgroundColor: "transparent",
    boxSizing: "border-box",
    cursor: "pointer"
  },
  socialButton: {
    margin: theme.styles?.footer?.socialRow?.button?.margin ?? "none",
    borderRadius: 57,
    borderWidth: 0,
    boxShadow: theme.styles?.footer?.socialRow?.button?.boxShadow ?? "0px 0px 2px #888",
    padding: "0.2em 0.25em",
    fontSize: 35,
    height: 52.5,
    width: 52.5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textDecoration: "none",
    cursor: "pointer",
    backgroundColor: theme.styles?.footer?.socialRow?.button?.backgroundColor ?? theme.styles?.footer?.button?.backgroundColor,
    color: theme.styles?.footer?.socialRow?.button?.color ?? theme.styles?.footer?.button?.color ?? theme.styles?.footer?.color,
    [theme.breakpoints.down('xs')]: {
      height: 36,
      width: 36,
      fontSize: 16,
    },
  },
  buttonIconWithText: {
    marginRight: 10
  },
  buttonIcon  : {
    color           : theme.styles?.footer?.button?.color,
  },
  actionButton: {
    boxShadow       : theme.styles?.footer?.actionRow?.button?.boxShadow ?? "none",
    display         : "flex",
    flexDirection   : "column",
    alignItems      : "center",
    justifyContent  : "center",
    backgroundColor : theme.styles?.footer?.actionRow?.button?.backgroundColor ?? theme.styles?.footer?.button?.backgroundColor,  
    color           : theme.styles?.footer?.actionRow?.button?.color ?? theme.styles?.footer?.button?.color ?? theme.styles?.footer?.color,
    textDecoration  : "none",
    width           : theme.styles?.footer?.actionRow?.button?.width ?? 135,
    height          : 80,
    borderRadius    : 10,
    margin          : theme.styles?.footer?.actionRow?.button?.margin ?? 15,
    textAlign       : "center",
    [theme.breakpoints.down('xs')]  : {
      width     :  theme.styles?.footer?.actionRow?.button?.width ?? 120,
      height    : 65,
    },
  },
  buttonLabel: {
    fontSize        : 20,    
    color           : theme.styles?.footer?.button?.color,
    margin          : "5px 15px",
    [theme.breakpoints.down('xs')]  : {
      fontSize  : 16,
    },
  },  
}));