import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { useCustomizations } from "@personicom/customizations";
import { ThemeEx, FooterConfig, IPlayerProps } from './player-types';
import FooterRow from "./components/footer/footer-row";
import { getIsEmbeded } from 'features/player/player-helpers';

interface IFooterProps {
  playerProps: Partial<IPlayerProps>;
};

const AppFooter = ({ playerProps }: IFooterProps) => {
  const { footer } = useCustomizations();
  const classes   = buildStyles({footer});    //TODO: need to add page to the customizations
  
  if (!footer || !footer.rows || getIsEmbeded()) return null;
  const footerConfig = (footer as any) as FooterConfig;

  return (
    <Grid id="app-footer" container justifyContent="center" alignItems="center" className={classes.footerRoot}>
      {_.map(footerConfig.rows, row => <FooterRow key={row.id} row={row} playerProps={playerProps} />)}
    </Grid>
  );
}

export default AppFooter;

const buildStyles = makeStyles((theme: ThemeEx) => ({
  footerRoot: {
    paddingTop: 25,
    borderTop: `5px solid ${theme.styles?.page?.borderColor}`, //`
    margin: theme.styles?.footer?.margin ?? "0 0 auto 0",
    backgroundColor: `${theme.styles?.footer?.backgroundColor ?? theme.styles?.page?.backgroundColor}`, //`
  },
}));