import React, {  } from "react";
import { Button, ButtonBaseTypeMap, Chip, ExtendButtonBase } from "@material-ui/core";
import { useActionConfig, useFormContext } from "./form-context";

export interface IFormActionProps extends Omit<ExtendButtonBase<ButtonBaseTypeMap<{}, "button">>, "onClick"> {
  id: string;
}

const FormAction = ({id, ...otherProps}: IFormActionProps) => {
  const { onClick } = useFormContext();
  const { actionConfig, dynamicProps } = useActionConfig(id);

  const onActionClick = (e: any) => {
    onClick(actionConfig!.id, e);
  }

  if(!actionConfig){
    return (<Chip
      label={`no config for action: ${id}`}
      color="secondary"
      variant="outlined"
    />);
  }

  return (
    <Button 
      id={actionConfig.id}
      onClick={onActionClick}
      {...otherProps}
      variant={actionConfig.variant ?? "outlined"}
      size={actionConfig.size ?? "medium"}
      color="primary"
      {...actionConfig.componentProps}
      {...dynamicProps}>
        {actionConfig.label}
      </Button>
  );
};

export default FormAction;