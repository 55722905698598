import { useContext } from "react";
import { AppInsightsContext } from "./appinsights-provider";
import { IAppInsightsContext } from "./appinsights-types";
import { IFlatCustomizations } from "@personicom/customizations";
import { VideoMetadata } from "player-types";

const trackEventWrapper = (aiContext: IAppInsightsContext) => (eventName: string, customizations: IFlatCustomizations, videoProps: VideoMetadata | null, additionalProps?:any) => {

  var eventData = {  
    environmentName: customizations.environment,
    rootDomain: videoProps?.rootDomain,
    subdomain: videoProps?.subdomain,
    renderId: videoProps?.renderId,
    campaignId: videoProps?.campaignId,
    videoId: videoProps?.videoId,
    videoAk: videoProps?.videoAk,
    operationId: videoProps?.operationId,
    SID: videoProps?.videoAk, 
  };

  if(additionalProps) eventData = {...eventData, ...additionalProps};

  return aiContext.trackEvent(eventName, eventData);
};

///===
/// Hook to get the trackEvent method from the app insights context
export function useTrackPlayerEvent(){
  const aiContext = useContext<IAppInsightsContext | null>(AppInsightsContext);

  if(!aiContext) throw Error("useTrackEvent can only be called from within AIProvider");
  return trackEventWrapper(aiContext);
}

///===
/// Hook to get current state of tracking in the app
export function useIsPlayerTracking() {
  const aiContext = useContext<IAppInsightsContext | null>(AppInsightsContext);
  if(!aiContext) throw Error("useTrackEvent can only be called from within AIProvider");
  return aiContext.isTracking;
}

///===
/// Hook to get all the app insights stuff from the provider
export function usePlayerAppInsights(){
  const aiContext = useContext<IAppInsightsContext | null>(AppInsightsContext);
  if(!aiContext) throw Error("useTrackEvent can only be called from within AIProvider");
  
  return {
    initialize: aiContext.initialize,
    trackEvent: trackEventWrapper(aiContext),
    isTracking: aiContext.isTracking,
  };

}