import { useState } from "react";
import { Box, Divider, Grid, makeStyles, Theme, Typography, useMediaQuery } from "@material-ui/core";
import { IAuthFormSchema, IAuthFormProps } from "./auth-types";
import FormProvider from "features/form-builder/form-context";
import FormLayout from "features/form-builder/form-layout";
import { subYears } from "date-fns";
import { ErrorCard, useCustomizations } from "@personicom/customizations";
import { validateFields } from "features/form-builder/form-utils";
import { convertValues } from "features/form-builder/form-utils-converter";

const defaultValues: IAuthFormSchema = {
  firstName: "",
  dob: null, //new Date(),
  accessCode: "",
};

const buildStyles = makeStyles((theme: any) => ({
  content: {
    [theme.breakpoints.down("xs")]: {
      padding: "1em 0",
    }
  },
  tabHeader: {
    borderStyle: "solid",
    borderColor: "#ccc",
    borderWidth: "0.5px 0.5px 0 0.5px",
    borderRadius: "10px 10px 0 0",
    padding: "0.5em 1em",
    zIndex: 1,
    background: "white",
    display: "flex",
    alignItems: "center",
    position: "relative",
    columnGap: "5px",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "90%",
      border: "none",
    }
  },
  imageBox: (props: any) => ({
    height: 50,
    width: 224,
    backgroundSize: "contain",
    backgroundImage: `url(${props.headerImage})`,
    backgroundRepeat: "no-repeat",
    marginBottom: "0.25em",
  }),
  betaBox: (props: any) => ({
    height: 45,
    width: 90,
    backgroundSize: "contain",
    backgroundImage: `url(${props.betaImage})`,
    backgroundRepeat: "no-repeat",
    marginBottom: "0.25em"
  }),
  watermarkBox: {
    position: "absolute",
    left: 0,
    top: 0,
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    height: "100%",
    width: "100%",
    zIndex: -99
  },
  watermark: (props: any) => ({
    height: 300,
    width: 400,
    backgroundSize: "contain",
    backgroundImage: `url(${props.betaImage})`,
    backgroundRepeat: "no-repeat",
    opacity: 0.05,
    filter: "grayscale(1)",
    transform: "rotate(-35deg) translateY(8em) translateX(-2.75em)",
  }),
  divider: {
    width: "100%",
    height: "0.5px",
    backgroundColor: "#ccc",
    marginTop: "0.5em",
    marginBottom: "0.5em",
  },
  title: {
    flexWrap: "nowrap",
    whiteSpace: "nowrap",
    fontSize: "24px",
    fontWeight: 900,
    margin: "0.25em 0 0.25em 0",
    color: theme.palette.grey[900],
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.5em",
      whiteSpace: "break-spaces",
      textAlign: "center",
    },
  },
  footer: {
    borderWidth: "0.5px 0 0.5px 0",
    border: "solid #ccc",
    padding: "2em 0",
    marginTop: "3em",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
    }
  },
  browserSupportBox: {
    borderLeft: "0.5px solid #ccc",
    [theme.breakpoints.down('xs')]: {
      borderLeft: "none",
    }
  },
  browserSupport: {
    marginTop: "0.75em !important",
    display: "flex",
    alignItems: "center",
    columnGap: "8px",
    fontSize: "0.75em",
    "& svg": {
      color: "#666",
    }
  },
  copyright: {
    fontSize: "10px",
    fontWeight: 400,
  }
}));

const AuthForm = ({ formConfig, onAuthChange }: IAuthFormProps) => {
  const { images, blobUrl, blobRootUrl } = useCustomizations();
  const classes = buildStyles({ headerImage: blobUrl(images.authHeader), betaImage: blobRootUrl(images.beta) });
  const [error, setError] = useState<string | null>(null);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

  //---
  //Async method to perform the action and deal with the result
  const doAuthChange = async (actionId: string, values: Record<string, any>) => {
    const result = await onAuthChange({ actionId, values });
    if (result) {
      //If there is a result to this call, it will be an error string
      setError(result);
    }
  }

  //---
  // Handles a FormAction click event. Looks for the Submit action and completes the auth flow
  const handleAction = (actionId: string, values: Record<string, any>): boolean | Record<string, string> => {
    const action = formConfig.actions.find(a => a.id === actionId);
    if (!action) throw new Error(`Action with id: ${actionId} not found. This is an internal error.`);

    if (action.isSubmit) {
      const actualValues = convertValues(formConfig.fields, values);
      const errors = validateFields(formConfig.fields, actualValues);

      if (!!errors) {
        console.log("form validation errors: ", errors);
        console.log("form values: ", actualValues);
        return errors;
      }
      else {
        doAuthChange(actionId, actualValues);
      }
    }

    //TODO: what to do with other actions?
    return true;
  }

  const onTouched = () => {
    if (error) setError(null);
  }

  // TODO: Need to decide how to handle evaluated config for fields
  // For example, on the DOB field in this config, need to set the minDate and maxDate
  // to dynamic values based on the current date. Perhaps look at Handlebars?
  // The solution below is an option, but it assumes the code has knowledge of the
  // config for this form.
  const dynamicProps = {
    dob: {
      minDate: subYears(new Date(), 200),
      maxDate: new Date(),
    }
  };

  const accessCodeLink = formConfig.links.find(link => link.id === 'accessCode');
  const termsPolicyLink = formConfig.links.find(link => link.id === 'termsPolicy');

  return (
    <Box sx={{ minHeight: "98vh", display: "flex", alignItems: "center" }}>
      <Grid container justifyContent="center" direction="column">
        <FormProvider
          config={formConfig}
          initialValues={defaultValues}
          handleAction={handleAction}
          dynamicProps={dynamicProps}
          onTouched={onTouched}
        >

          <Box sx={{
            maxWidth: "900px",
            margin: "auto",
            width: { xs: "88%", sm: "90%" },
            p: { xs: "0.5em", sm: "0 1.25em" },
            position: "relative",
            fontSize: "95%"
          }} >

            {/* The watermark */}
            <Box className={classes.watermarkBox}>
              <Box className={classes.watermark} />
            </Box>

            {/* The header */}
            <Grid container direction="column">
              <Grid container justifyContent="space-between" alignItems="center" spacing={3}>
                <Grid item xs={12} sm={6} container justifyContent={isMobile ? "center" : "flex-start"}>
                  <Box className={classes.imageBox} />
                </Grid>
                <Grid item xs={12} sm={6} container justifyContent={isMobile ? "center" : "flex-end"}>
                  <Box className={classes.betaBox} />
                </Grid>
              </Grid>
              <Divider orientation="horizontal" flexItem className={classes.divider} />
              <Typography variant="h1" color="primary" className={classes.title}>{formConfig.title}</Typography>
              <Divider orientation="horizontal" flexItem className={classes.divider} />
            </Grid>

            <Grid container item>
              <Grid container direction="column" className={classes.content}>
                <Grid id="auth-dialog-grid" container>
                  {error && <ErrorCard message={error} hideHeader />}
                  <FormLayout {...formConfig} />
                </Grid>
              </Grid>

              <Grid container className={classes.footer}>
                <Grid item xs={12} sm={7} container direction="column" alignItems={isMobile ? "center" : "flex-start"}>
                  {accessCodeLink &&
                    <>
                      <Typography>More Login Information</Typography>
                      <a href={accessCodeLink.url} target="_blank" rel="noreferrer">
                      {accessCodeLink.label}
                      </a>
                    </>
                  }
                </Grid>
              </Grid>
              <Box sx={{ marginTop: "0.5em", width: "100%" }}>
                <Grid container>
                  <Grid item xs={12} sm={8}>
                    <Typography className={classes.copyright}>Copyright &#169;2023 Cambium Assessment, Inc. All rights reserved</Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} container justifyContent={isMobile ? "center" : "flex-end"}>
                    {termsPolicyLink &&
                      <>
                        <a href={termsPolicyLink.url}  target="_blank" rel="noreferrer">
                        {termsPolicyLink.label}
                        </a>
                      </>
                    }
                  </Grid>
                </Grid>
              </Box>
            </Grid>

          </Box>
        </FormProvider>
      </Grid>
    </Box>
  );
};

export default AuthForm;