import { getApiBaseUrl, getMediaUrl } from "features/player/player-helpers";
import { logApiError } from "helpers/api-helpers";
import { useContext } from "react";
import { AuthContext } from "./auth-provider";
import { IAuthContext } from "./auth-types";
import { createHash } from "crypto";
import { isDate } from "date-fns";

//----
// Hook to access the Auth context
export function useAuth(){
  const authContext = useContext<IAuthContext>(AuthContext);
  return authContext;
}

//----
//Function to perform the authentication
export async function doAuthenticationApi(client: string, subdomain: string, values: Record<string, any>){
  try{
    const endpoint = "authorizeVideo";  //TODO: change when we have the real endpoint
    const url = `${getApiBaseUrl(endpoint)}/${client}/${subdomain}/`;
    const bodyData = JSON.stringify(values);

    const response = await fetch(url, { method: "POST", body: bodyData });
    //TODO: need to get more specific with errors - e.g. unauthorized, etc. 
    // show human-readable messages
    if(!response.ok || !response.json) throw response;
    
    const data = await response.json();
    return {
      isError: false,
      data,
    };
  }
  catch(ex: any){
    logApiError(ex);
    return {
      isError: true,
      error: `${ex.status}: ${ex.statusText}`,
    };
  }
}

//----
//Function to perform the authentication
export async function doAuthentication(environment: string, subdomain: string, mediaPath: string, values: Record<string, any>): Promise<any> {
  try{
    //hash the DOB (MMddyyy) and Access Code with a SHA256 and try to GET the blob with that name
    const dob = formatDateForHash(values["dob"]);
    console.log("formatted dob: ", dob);

    const hashInput = `${values.accessCode}${dob}`;
    
    //NOTE: the "crypto" library is not officially supported in the browser through create-react-app anymore
    // so I had to use a workaround to be able to use this library. See details here:
    // https://github.com/facebook/create-react-app/issues/11756
    // Specifically, this comment is what I used to work around it: https://github.com/facebook/create-react-app/issues/11756#issuecomment-1475117973
    const hash = createHash("sha256");
    hash.update(hashInput);

    //TODO: DEBUG
    const hashOut = hash.digest("hex");    
    // const hashOut = "63b2c3661d2a7ed334ba6c27b930434ec9e11807f50cd08f11fd3c39d57d776e"; //PROD
    // const hashOut = "50d558746f3ab1c3a39fbe173a2ef27dd5c04a2c9b65cc941e5faca210ce660e"; //QA

    const url = getMediaUrl(environment, subdomain, mediaPath, hashOut);

    const response = await fetch(url, { method: "GET" });
    console.log("hash get response", response);

    if(response.status === 404){
      console.log("invalid login attempt.");
      return {
        isError: true,
        error: "Login Failed. Please confirm you have entered the correct information.",
      };
    }

    // //TODO: need to get more specific with errors - e.g. unauthorized, etc. 
    // // show human-readable messages
    // if(!response.ok || !response.json) throw response;
    
    const data = await response.json();
    return {
      isError: false,
      data
    };
  }
  catch(ex: any){
    logApiError(ex);
    return {
      isError: true,
      error: `${ex.status}: ${ex.statusText}`,
    };
  }
}

const formatDateForHash = (dob: Date | string) => {
  const value = isDate(dob) ? dob as Date : new Date(dob);
  const month = value.getMonth() + 1; // add 1 to the month value to get 1-12 range
  const day = value.getDate();
  const year = value.getFullYear();
  const formattedDate = `${month.toString().padStart(2, '0')}${day.toString().padStart(2, '0')}${year.toString()}`;  
  return formattedDate;
}