import { Grid, Theme, makeStyles, useMediaQuery } from "@material-ui/core";
import FormAction from "./form-action";
import FormField from "./form-field";
import { IFormAction, IFormField, IFormLayout } from "./form-types";
import { createFieldStyle } from "./form-utils";

export interface IFormLayoutProps {
  fields?: IFormField[];
  actions?: IFormAction[];
  layout?: IFormLayout;
}

const buildStyles = makeStyles((theme: any) => ({
  layoutContainer: (props: IFormLayout | undefined) => ({
    flexWrap: "nowrap",
    ...props?.container,
  }),
  fieldsContainer: (props: IFormLayout | undefined) => ({
    display: props?.grid ? "grid" : "flex",
    gridColumnGap: theme.spacing(1),
    rowGap: theme.spacing(1),
    ...props?.grid,
    ...props?.flex,
    [theme.breakpoints.down("xs")]: {
      padding: 0,
      width: "95%",
    },
  }),
  actionsContainer: (props: IFormLayout | undefined) => ({
    display: "grid",
    gridColumnGap: theme.spacing(1),
    gridRowGap: theme.spacing(1),
    marginTop: theme.spacing(1),
    justifyContent: "flex-end",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
    },
    ...props?.actions,
  }),
}));

const FormLayout = ({ layout, fields, actions }: IFormLayoutProps) => {
  //TODO: update the config, and this to support all the breakpoints ("xs", "sm", "md", "lg")
  const isSmallBp = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  if(!fields && !actions) return null;
  const classes = buildStyles({...layout});

  return (
    <Grid id="form-layout-grid" container direction="column" className={classes.layoutContainer}>

      <div className={classes.fieldsContainer}>
        {fields?.map(field => {
          return (
            <div 
              id={`${field.id}-div`} 
              key={field.id} 
              style={createFieldStyle(field, isSmallBp)}>
                <FormField fieldConfig={field} />
            </div>
          )
        })}
      </div>

      {actions && (
        <div className={classes.actionsContainer}>
          {actions.map(action => {
            return (
              <div 
                id={`${action.id}-div`} 
                key={action.id} 
                style={createFieldStyle(action, isSmallBp)}>
                  <FormAction id={action.id} />
              </div>
            )
          })}
        </div>
      )}

    </Grid>
  );
};

export default FormLayout;