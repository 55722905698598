import { ChangeEvent, useMemo } from "react";
import { Chip, MenuItem, TextField, TextFieldProps, Tooltip } from "@material-ui/core";
import { useFieldConfig, useFormContext } from "./form-context";
import { FormFieldOption } from "./form-types";
import { createSelectOptions } from "./form-utils";

interface IRemoveFromProps {
  id: string;
  value: any;
  onChange: any;
  select: any;
}

export interface IFormSelectFieldProps extends Omit<TextFieldProps, keyof IRemoveFromProps> {
  id: string;
}

const FormSelectField = ({id, ...otherProps}: IFormSelectFieldProps) => {
  const { values, errors, updateValue } = useFormContext();
  const { fieldConfig, dynamicProps } = useFieldConfig(id);
  const options = useMemo<FormFieldOption[]>(() => createSelectOptions(fieldConfig?.options), [fieldConfig?.options]);
  const rootId = fieldConfig!.id;

  const handleFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    const fieldKey = rootId;
    const value = e.target.value;
    updateValue(fieldKey, value);
  }

  if(!fieldConfig){
    return (
      <Tooltip title={`no config for field: ${id}`}>
        <Chip
          label="error"
          color="secondary"
          variant="outlined"
        />
      </Tooltip>
    );
  }

  return (
    <TextField 
      id={id}
      select={true} 
      value={values[rootId] ?? ""} 
      error={!!errors[rootId]}
      helperText={errors[rootId]}
      onChange={handleFieldChange} 
      label={fieldConfig.label} 
      placeholder={fieldConfig.placeholder} 
      fullWidth
      {...otherProps}
      {...fieldConfig.componentProps}
      {...dynamicProps}>
      
      {options?.map(opt => <MenuItem key={opt.value} value={opt.value}>{opt.label}</MenuItem>)}

    </TextField>
  );
};

export default FormSelectField;