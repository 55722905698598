import React, { Component } from 'react';
import cc from 'classcat';
// import './player-elements.css';


export default class PlayerElements extends Component {
  constructor(props, context) {
    super(props, context);
    this.computeCurrentFrame = this.computeCurrentFrame.bind(this);
  }

  /*
   * Determine what frame number we're on.
   * currentTime is given as a double, generally to thousands of a second.
   */
  computeCurrentFrame() {
    return (this.props.player.currentTime * this.props.fps);
  }

  //data-img-src={this.props.imageUrl} 

  render() {
    if (!this.props.children) {
      return <div />
    }

    const childrenWithProps = React.Children.map(this.props.children, child =>
      React.cloneElement(child, { currentTime: this.props.player.currentTime, fps: this.props.fps, currentFrame: this.computeCurrentFrame() })
    );

    return (
      <div className='interactive-elements'>
        {childrenWithProps}
      </div>
    );
  }
}

const lerp = (x, y, a) => x * (1 - a) + y * a
const invlerp = (a, b, v) => clamp((v - a) / (b - a))
const clamp = (v, min = 0, max = 1) => Math.min(max, Math.max(min, v))

const easeLinear = (percent, elapsed, start, end, total) => start + (end - start) * percent;


export class PlayerElement extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      hideElement: true
    }

    this.handleClick = this.handleClick.bind(this);
    this.getCurrentKeyFrame = this.getCurrentKeyFrame.bind(this);
    this.refButton = React.createRef();
    this.tick = this.tick.bind(this);
  }

  handleClick(e) {
    const buttonType = e.currentTarget.id || e.currentTarget.title || "InteractiveButton";  
    const customizations = this.props.customizations;
    const videoProps = this.props.videoProps;

    /* Check if the URL has a scheme, if not add one. Otherwise URL is realtive to us. */
    var url = this.props.value;

    if (!/^([a-z][a-z0-9+\-.]*):/i.test(url)) {
      url = 'http://' + url;
    }
    
    console.log(`Click on: ${this.props.name}: ` + url)

    const aiEvent = {buttonType: buttonType, buttonUrl: e.currentTarget.href, InteractiveElementName: this.props.name, InteractiveElementTarget: url };
    this.props.trackEvent("InteractiveClickThrough", customizations, videoProps, aiEvent);

    window.open(url);
  }

  parseCSSText(cssText) {
    var cssTxt = cssText.replace(/\/\*(.|\s)*?\*\//g, " ").replace(/\s+/g, " ");
    var style = {}, [, ruleName, rule] = cssTxt.match(/ ?(.*?) ?{([^}]*)}/) || [, , cssTxt];
    var cssToJs = s => s.replace(/\W+\w/g, match => match.slice(-1).toUpperCase());
    var properties = rule.split(";").map(o => o.split(":").map(x => x && x.trim()));
    for (var [property, value] of properties) style[cssToJs(property)] = value;
    return { cssText, ruleName, style };
  }

  componentDidMount() {
    this.KeyFrames = [];

    var arr = this.props.children;

    for (var i = 0, l = (arr.length); i < l; i++) {
      var kf = arr[i];
      var nextKF = arr[i + 1];
      var prevKF = arr[i - 1];
      var newKF = [];

      newKF.startFrameNumber = kf.props.frameNumber;
      newKF.startCssStyle = (kf.props.cssStyle) ? this.parseCSSText(kf.props.cssStyle).style : this.parseCSSText(prevKF.props.cssStyle).style;
      //HACK: Figure out a way to specify which attributes need to lerp. Compare previous and current?
      newKF.endFrameNumber = nextKF ? nextKF.props.frameNumber : null;
      newKF.endCssStyle = nextKF ? ((nextKF.props.cssStyle) ? this.parseCSSText(nextKF.props.cssStyle).style : newKF.startCssStyle) : newKF.startCssStyle;
      newKF.noEnd = nextKF ? ((nextKF.props.noEnd) ? true : false) : (kf.props.noEnd);
      newKF.animateAtt = {
        startingValue: parseFloat(newKF.startCssStyle.top),    // Start Point
        endingValue: nextKF ? parseFloat(newKF.endCssStyle.top) : parseFloat(newKF.startCssStyle.top),      // End Point
        property: 'top',                                    // CSS Custom property
        currentValue: parseFloat(newKF.startCssStyle.top), // Starting point
        currentCss: newKF.startCssStyle,
        suffix: 'px'                                // Optional suffix (px, deg, turn)
      }
      this.KeyFrames[i] = newKF;
    }

    //HACK: prop isDebug isn't coming through
    this.baseStyle = {
      position: "absolute",
      zIndex: "1",
      //border: this.props.isDebug ? "1px solid red":"none"
      border: this.props.isDebug ? "1px solid red" : "none"
    }

    //Hookup animations.
    window.requestAnimationFrame(this.tick);
  }


  getCurrentKeyFrame() {
    var currentFrame = this.props.currentFrame;
    var currentKeyFrame = undefined;

    if (!this.KeyFrames)
      return;

    for (var i = 0, l = this.KeyFrames.length; i < l; i++) {
      var kf = this.KeyFrames[i];
      if ((currentFrame >= kf.startFrameNumber) && ((currentFrame < kf.endFrameNumber) || kf.noEnd)) {
        currentKeyFrame = kf;
        continue;
      }
    }

    return currentKeyFrame;
  }

  /*
   * Called by RequestAnimationFrame.
   */
  tick() {
    var currentKeyFrame = this.getCurrentKeyFrame();
    var currentFrame = this.props.currentFrame;

    if (!currentKeyFrame) {
      requestAnimationFrame(this.tick);
      return;
    }


    //easeLinear = (percent, elapsed, start, end, total)
    var animateAttribute = currentKeyFrame.animateAtt;

    //Nothing left to animate.
    if (animateAttribute.currentValue === animateAttribute.endingValue) {
      requestAnimationFrame(this.tick);
      return;
    }

    var fps = this.props.fps;
    var currentTimeMs = (this.props.currentTime * 1000);

    var elaspedTimeMs = currentTimeMs - ((currentKeyFrame.startFrameNumber / fps) * 1000);

    var totalTimeMs = ((currentKeyFrame.endFrameNumber - currentKeyFrame.startFrameNumber) / fps) * 1000;

    var percentComplete = ((elaspedTimeMs > totalTimeMs ? totalTimeMs : elaspedTimeMs) / totalTimeMs);

    animateAttribute.currentValue = easeLinear(percentComplete, elaspedTimeMs, animateAttribute.startingValue, animateAttribute.endingValue, totalTimeMs);

    //Set the CSS
    animateAttribute.currentCss.top = clamp(animateAttribute.currentValue, animateAttribute.endingValue, animateAttribute.startingValue) + animateAttribute.suffix;

    if (this.props.isDebug && this.props.name === 'clickableLine1URL') {
      console.log('elaspedTimeMs: ' + elaspedTimeMs + ' totalTimeMs: ' + totalTimeMs + ' percentComplete: ' + percentComplete + ' currentValue:' + animateAttribute.currentValue + ' set: ' + animateAttribute.currentCss.top);
    }


    this.forceUpdate();


    //Queue next call.
    requestAnimationFrame(this.tick);
  }


  render() {
    const currentKeyFrame = this.getCurrentKeyFrame();
    const currentFrame = this.props.currentFrame;
    if (currentKeyFrame) {
      //TODO: TWEEN
      this.cssStyle = { ...this.baseStyle, ...currentKeyFrame.animateAtt.currentCss };
    } else {
      this.cssStyle = { ...this.baseStyle };
    }


    return (
      <button
        ref={this.refButton}
        type='button'
        key={this.props.name}
        data-current-frame={currentFrame}
        data-current-key-frame-start={currentKeyFrame ? currentKeyFrame.startFrameNumber : undefined}
        data-current-key-frame-end={currentKeyFrame ? currentKeyFrame.endFrameNumber : undefined}
        data-current-key-frame-noend={currentKeyFrame ? currentKeyFrame.noEnd : undefined}
        className={cc([
          'interactive-element',
          this.props.className,
          {
            'interactive-element-hide': (!currentKeyFrame)
          }
        ])}
        style={this.cssStyle}
        onClick={this.handleClick}
      />
    );
  }
}


export class KeyFrame extends Component {
  render() {
    return (
      <p />
    );
  }
}


