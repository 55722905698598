import { IFormField } from "./form-types";

//regex to extract the handlebars items from the string: "hello {{name}} how are you doing {{timeofday}}?".
const hbRegex = /{{([^}]*)}}/g;

//A converter that takes a string and returns a different type
export type ValueConverter = (value: string) => any;

//The converters we're currently supporting
const converters: Record<string, ValueConverter> = {
  date: (value: string) => (new Date(value)).toISOString(),
  default: (value: string) => value,
  //TODO: add more converters as we support more types
};

//====
// Will convert a field value to a specific data type, and perform any template
// transpiling that is necessary
const convertValue = (field: IFormField, values: Record<string, any>) => {
  let theValue = values[field.id]; //default it to the value for this field
  let isIncomplete = false;     //flags that one of the template values is null / undefined

  //If there is a template, need to construct the value to convert
  if(field.valueConverter!.template){
    const template = field.valueConverter!.template;
    const replaceKeys = template.match(hbRegex);  //extract the {{x}} keys

    if(replaceKeys){
      const replacements = replaceKeys?.map(key => ({key, value: values[key.substring(2, key.length - 2)]}));
      isIncomplete = !!replacements.find(r => !r.value);  //if there's a key without a value, then the template is incomplete
      theValue = replacements.reduce((output, item) => output.replace(item.key, item.value), template);
    }
  }

  if(isIncomplete) return null;   //since we don't have all the information, return null for this value

  //Convert the value
  const converter = converters[field.valueConverter!.dataType] ?? converters.default;
  const convertedValue = converter(theValue);

  return convertedValue;
}
    
//====
// Will convert any values that need a conversion and return the final values object.
// this recognizes and handles groups (rows) with a valueConverter to convert one value 
// into another for purposes of validation and sending to the api
// currently does not removed values that are used in a template from the master values object.
export const convertValues = (fields: IFormField[], values: Record<string, any>) : Record<string, any> => {
  
  const toConvert = fields.filter(f => f.valueConverter);
  if(toConvert.length === 0) return values;   //nothing to do here

  const compiledValues = toConvert.reduce((result, field) => {
    const converted = convertValue(field, values);
    console.log(`field ${field.id} converted to ${converted}`);
    //TODO: remove the values that I used here from the master values structure??

    //return the new value object with the converted value;
    return {
      ...result,
      [field.id]: converted,
    };
  }, values);

  return compiledValues;
}