import { ChangeEvent } from "react";
import { Chip, TextField, TextFieldProps, Tooltip } from "@material-ui/core";
import { useFieldConfig, useFormContext } from "./form-context";

interface IRemoveFromProps {
  id: string;
  value: any;
  onChange: any;
}

export interface IFormTextFieldProps extends Omit<TextFieldProps, keyof IRemoveFromProps> {
  id: string;
}

const FormTextField = ({id, ...otherProps}: IFormTextFieldProps) => {
  const { values, errors, updateValue } = useFormContext();
  const { fieldConfig, dynamicProps } = useFieldConfig(id);

  const handleFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    const fieldKey = e.target.id;
    const value = e.target.value;
    updateValue(fieldKey, value);
  }

  if(!fieldConfig){
    return (
      <Tooltip title={`no config for field: ${id}`}>
        <Chip
          label="error"
          color="secondary"
          variant="outlined"
        />
      </Tooltip>
    );
  }

  const needsLabel = (!fieldConfig.labelPosition || fieldConfig.labelPosition === "inline");

  return (
    <TextField 
      id={id} 
      value={values[id]} 
      error={!!errors[id]}
      helperText={errors[id]}
      onChange={handleFieldChange} 
      label={needsLabel ? fieldConfig.label : undefined} 
      placeholder={fieldConfig.placeholder} 
      fullWidth
      {...otherProps}
      {...fieldConfig.componentProps}
      {...dynamicProps} />
  );
};

export default FormTextField;