import ReactGA from 'react-ga';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { trackEvent, createUrl, useVideoDownload, useDownloadProperties } from './player-helpers';
import { useCustomizations } from '@personicom/customizations';
import { usePlayerContext } from 'features/context/player-context-hooks';
import { useTrackPlayerEvent } from 'features/appinsights-provider';

//TODO: change the envent to be embeded button click or something?
const EVENT_CATEGORY  = "ClickLandingPageButton";

function SharePanel({ renderId, buttons, playerProps, videoProps, videoUrl, onClose}){
  const { subdomain } = useCustomizations();
  
  return (
    <div className="share-panel">
      <div className="share-panel-header">
        <h3>Sharing Menu</h3>
        <button className="close-button" onClick={onClose} title="Close sharing panel">
          <i className="fa fa-times"/>
        </button>
      </div>
      
      <ul className="share-list">
        {buttons.map(btn => {
          return (
            <li key={btn.id} className="share-list-item" title={btn.tip}>
              {btn.type === "download-button" && <ShareDownloadButton renderId={renderId} btn={btn} subdomain={subdomain} playerProps={playerProps} videoUrl={videoUrl} />}
              {btn.type === "link" && <ShareLink renderId={renderId} btn={btn} playerProps={playerProps} videoProps={videoProps} subdomain={subdomain}/> }
            </li>
          );
        })}
      </ul>

    </div>
  );
}

export default SharePanel;

function ShareLink({renderId, btn, playerProps, videoProps, subdomain}){
  const customizations = useCustomizations();
  const trackEvent = useTrackPlayerEvent();
  const url = createUrl(btn.url, null, playerProps, videoProps);//Hack to handle templat items that contain template items.
  
  function onClick(e){
    const aiEvent         = { buttonType: btn.id, buttonUrl: url };
    const gaEvent         = { category: EVENT_CATEGORY, action: btn.id, label: `${subdomain}:${playerProps.videoId}` };

    trackEvent(EVENT_CATEGORY, customizations, videoProps, aiEvent);
    ReactGA.event(gaEvent, ['pstracker']);    
  }
  return (
    <a href={url} target="_blank" onClick={onClick} rel="noreferrer">
      <i className={btn.icon}/>
      <span>{btn.tip}</span>
    </a>
  )
}

function ShareDownloadButton({ renderId, btn, playerProps, subdomain, videoUrl}){
  const customizations = useCustomizations();
  const {videoProps} = usePlayerContext();
  const urlInfo     = useDownloadProperties(subdomain, playerProps, videoUrl);
  const [onDownload, isDownloading, downloadPercent]  = useVideoDownload(urlInfo);
  const trackEvent = useTrackPlayerEvent();
  
  function onClick(e){
    if(!isDownloading)
    {
      onDownload(e);

      const buttonType = e.currentTarget.id || e.currentTarget.title || "SharePanelDownloadButton";;
      const aiEvent         = { buttonType: buttonType, buttonUrl: e.currentTarget.href };
      const gaEvent         = { category: EVENT_CATEGORY, action: buttonType, label: `${subdomain}:${playerProps.videoId}` };

      trackEvent(EVENT_CATEGORY, customizations, videoProps, aiEvent);
      ReactGA.event(gaEvent, ['pstracker']);
    }
  }


  return (
    <a href="#" onClick={onClick} className="download-link">
      {isDownloading &&
        <div className="progress-container"> 
          <CircularProgressbarWithChildren value={downloadPercent} strokeWidth={6} >
            <i className="fa fa-download app-icon" alt={`Downloading: ${downloadPercent}%`} />
          </CircularProgressbarWithChildren>
        </div>
      }
      {!isDownloading && 
        <i className= "fa fa-download app-icon"></i>
      }

      <span>{btn.tip}</span>
    </a>
  );
}