export type TransformFunc<T> = (value: number) => T;
const defaultTransform = (value: number) => value;

//---
// Creates an array filled with numbers from the start to the end values.
// can transform the numbers to a different type based on the generic type param and an optional transformer
// function parameter, if desired.
// usage:
//  const numArray = arrayRange(10, 13); // [10, 11, 12, 13]
//  const stringArray = arrayRange(10, 13, n => n.toString()); // ["10", "11", "12", "13"]
export const arrayRange = <T = number>(start: number, end: number, transformer?: TransformFunc<T>): T[] => {
  const transform = transformer ?? defaultTransform;
  const values = Array.from({ length: end - start + 1 }, (_, index) => transform(start + index) as T);
  return values;
}