
import { Chip, makeStyles, Tooltip, Typography, TypographyProps } from "@material-ui/core";
import { useFieldConfig } from "./form-context";
import { IFormField } from "./form-types";

interface IRemoveFromProps {
  id: string;
}

export interface IFormLabelFieldProps extends Omit<TypographyProps, keyof IRemoveFromProps> {
  id: string;
}

//Turns the componentClasses on the field into a class we can use
const buildStyles = makeStyles((theme: any) => ({
  label: (props: any) => {
    const style = {
      ...props,
    };
    return style;
  },  
}));

const FormLabelField = ({id, ...otherProps}: IFormLabelFieldProps) => {
  const { fieldConfig, dynamicProps } = useFieldConfig(id);
  const classes = buildStyles({...fieldConfig?.componentClasses});
  
  if(!fieldConfig){
    return (
      <Tooltip title={`no config for field: ${id}`}>
        <Chip
          label="error"
          color="secondary"
          variant="outlined"
        />
      </Tooltip>
    );
  }

  const labelContent = buildContent(fieldConfig);

  return (
    <Typography {...otherProps} {...fieldConfig.componentProps} className={classes.label} >
      {labelContent}
    </Typography>
  );
};

export default FormLabelField;

//TODO: convert to handlebars?
const buildContent = (fieldConfig: IFormField) => {
  let start = 0;
  let index = fieldConfig.label.indexOf("{{");
  if(index >= 0 && fieldConfig.children){
    const original = fieldConfig.label;
    let content = [];
    //Sub out any of the templates
    while(index >= 0){
      //Add any text between the start and the next template
      if(index > 0){ content.push(original.slice(start, index - 1)); }

      //swap out the template with the item
      const endIndex = original.indexOf("}}", index) + 2;
      const item = original.slice(index, endIndex);
      const key = item.replace("{{", "").replace("}}", "");   //TODO: regex
      const vbl = fieldConfig.children.find(v => v.id === key);
      if(!vbl) console.error(`field ${fieldConfig.id} is missing variable with id ${key}`);
      else {
        const element = getChildElement(vbl);
        content.push(element);
      }

      //setup the variables for the next loop
      start = endIndex;
      index = original.indexOf("{{", start);
      
      if(index < 0 && start <= (original.length - 1)){
        //add any text at the end, since we won't be looping again
        content.push(original.slice(start));
      }
    }


    return (
      <>{content.map((c, index) => <span key={index}>{c}</span>)}</>
    );
  }
  else if(!!fieldConfig.children){
    //TODO: this field has variables, but no template
    console.warn(`field ${fieldConfig.id} has variables but no templates`);
  }
  
  return fieldConfig.label;
}

const getChildElement = (vbl: IFormField) => {
  
  if(vbl.type === "link"){
    return (<a href={vbl.url!} target="_blank" rel="noreferrer" style={{marginLeft: "2px"}}>{vbl.label}</a>)
  }

  return <>{vbl.label}</>
}