import React, { useEffect  } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Grid, useMediaQuery } from "@material-ui/core";
import PersonicomPlayer from "./player";
import { useCustomizations, ErrorCard } from "@personicom/customizations";
import { microMQ, mobileMQ } from "./player-hooks";
import { IPlayerProps } from "player-types";
import { getIsEmbeded } from "./player-helpers";
import 'video-react/dist/video-react.css';
import { usePlayerContext } from "features/context/player-context-hooks";
import { useTrackPlayerEvent } from "features/appinsights-provider";

interface IPlayerViewProps {
  playerProps: Partial<IPlayerProps>;
}

const PlayerView = ({ playerProps }: IPlayerViewProps) => {
  const customizations = useCustomizations();
  const {client, subdomain, images, blobUrl } = useCustomizations();
  const { videoProps } = usePlayerContext();  
  const trackEvent = useTrackPlayerEvent();
    
  const backgroundImage = images?.playerViewBackground ? blobUrl(images.playerViewBackground) : null;  
  let playerStyle = undefined;
  if (backgroundImage)
  {
    playerStyle = backgroundImageStyle(backgroundImage);
  }

  const isEmbeded = getIsEmbeded();
  const isMobile = useMediaQuery(mobileMQ);
  const isMicro = useMediaQuery(microMQ);   
  console.log(`Player: isEmbeded: ${isEmbeded} isMobobile: ${isMobile} isMicro: ${isMicro}` );


  if(!videoProps?.videoId){
    return (
      <ErrorCard message="Invalid URL"/>
    );
  }

  return (
    <Grid item style={playerStyle}>
      <PersonicomPlayer 
        customizations={customizations}
        videoProps={videoProps} 
        playerProps={playerProps} 
        client={client} 
        subdomain={subdomain} 
        isEmbeded={isEmbeded} 
        isMobile={isMobile} 
        isMicro={isMicro}
        trackEvent={trackEvent}/>
    </Grid>
  );
}

export default PlayerView;

const backgroundImageStyle = (imageUrl:string|null) => {
  return {
    backgroundImage: `url(${imageUrl})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: "center",
    backgroundSize: "auto"  
  };
};
