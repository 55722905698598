import React, { ReactNode, useContext, useState } from "react";
import { LoadingIndicator } from "@personicom/customizations";
import { IFormField, IFormProps } from "./form-types";

export interface IFormProviderProps {
  children: ReactNode;
  config: IFormProps;
  initialValues: Record<string, any>;
  handleAction: (actionId: string, values: Record<string, any>) => boolean | Record<string, string>;
  //properties that can be passed down to the fields / actions, but need dynamic computation.
  // for example: {dateOfBirth: {minDate: new Date() }};
  dynamicProps?: Record<string, Record<string, any>>;
  onTouched?: (values: Record<string, any>) => void;
}

export interface IFormContext {
  config: IFormProps,
  values: Record<string, any>;
  errors: Record<string, string | null>;
  dynamicProps?: Record<string, Record<string, any>>;
  updateValue: (fieldId: string, value: any) => void;
  onClick: (actionId: string, e: MouseEvent) => void;
}

//---
// FormContext to store the information
export const FormContext = React.createContext<IFormContext | null>(null);

//---
// Hook for the FormContext
export function useFormContext(){
  const ctx = useContext(FormContext);
  return ctx!;
}

export function useFieldConfig(fieldId: string){
  const { config, dynamicProps } = useFormContext();
  let fieldConfig: IFormField | undefined = undefined;

  //need to check to see if this is nested field, or top-level
  if(fieldId.indexOf(".") <= 0){
    fieldConfig = config.fields.find(f => f.id === fieldId);
  }
  else{
    //id will be in the format of 'grandparent.parent.child'. Navigate the children
    // collection to get to the item 
    const ids = fieldId.split('.');
    const startField = config.fields.find(f => f.id === ids[0]);
    if(!startField) throw new Error(`field id is invalid: ${fieldId}`);      

    fieldConfig = ids.slice(1).reduce<IFormField>((parent, id) => {
      const child = parent.children?.find(f => f.id === id);
      if(!child) throw new Error(`field id is invalid: ${fieldId}`);
      return child;
    }, startField);
  }

  const dProps = dynamicProps ? dynamicProps[fieldId] ?? {} : {};
  return { fieldConfig, dynamicProps: dProps };
}

export function useActionConfig(actionId: string){
  const { config, dynamicProps } = useFormContext();
  const actionConfig = config.actions.find(itm => itm.id === actionId);
  const dProps = dynamicProps ? dynamicProps[actionId] ?? {} : {};
  return { actionConfig, dynamicProps: dProps };
}

export function useDynamicProps(id: string){
  const { dynamicProps } = useFormContext();
  if(dynamicProps) return dynamicProps[id];
  else return {};
}

//---
// Provider to provide data to the children
const FormProvider = ({children, config, initialValues, dynamicProps, handleAction, onTouched}: IFormProviderProps) => {
  const [values, setValues] = useState<Record<string, any>>(initialValues);
  const [errors, setErrors] = useState<Record<string, null | string>>({});

  const updateField = (fieldKey: string, fieldValue: any) => {
    const newValues = {
      ...values,
      [fieldKey]: fieldValue,
    };
    const newErrors = {
      ...errors,
      [fieldKey]: null,   //reset the error for now
    };
    
    setValues(newValues);
    setErrors(newErrors);
    onTouched && onTouched(newValues);
  }

  const onClick = (actionId: string, e: MouseEvent) => {
    const result = handleAction(actionId, values);
    if(result === true){
      //action succeeded...
    }
    else if (result === false){
      //action failed...
    }
    else {  //result is the errors object
      setErrors(result);
    }
  }

  return (
    <FormContext.Provider value={{
      config,
      values,
      errors,
      updateValue: updateField,
      onClick,
      dynamicProps,
    }}>
      {!config && <LoadingIndicator />}
      {!!config && children}
    </FormContext.Provider>
  )
};

export default FormProvider;