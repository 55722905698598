import FormTextField from "./form-text-field";
import FormDatePicker from "./form-datepicker-field";
import { IFormField } from "./form-types";
import { Chip, Tooltip } from "@material-ui/core";
import FormLabelField from "./form-label-field";
import FormSelectField from "./form-select-field";
import FormFieldWrapper from "./form-field-wrapper";
import FormRow from "./form-row";

export interface IFormFieldProps {
  fieldConfig: IFormField;
  idPrefix?: string;
}

const FormField = ({ fieldConfig, idPrefix = "" }: IFormFieldProps ) => {
  const { type: fieldType, id: fieldId } = fieldConfig;
  const unsupported = ["string", "date", "label", "select", "row"].indexOf(fieldType) < 0;
  const prefixSeparator = idPrefix === "" ? "" : ".";

  return (
    <>
      <FormFieldWrapper {...fieldConfig}>
        {fieldType === "string" && <FormTextField id={`${idPrefix}${prefixSeparator}${fieldId}`} />}
        {fieldType === "date" && <FormDatePicker id={`${idPrefix}${prefixSeparator}${fieldId}`} />}
        {fieldType === "label" && <FormLabelField id={`${idPrefix}${prefixSeparator}${fieldId}`} />}
        {fieldType === "select" && <FormSelectField id={`${idPrefix}${prefixSeparator}${fieldId}`} />}
        {fieldType === "row" && <FormRow id={`${idPrefix}${prefixSeparator}${fieldId}`} />}
      </FormFieldWrapper>

      {unsupported && 
        <Tooltip title={`unsupported field type '${fieldType}' for field '${idPrefix}${prefixSeparator}.${fieldId}}'`}>
          <Chip
            label="error"
            color="secondary"
            variant="outlined"
          />
      </Tooltip>
      }
    </>
  );
};

export default FormField;
