import { getEnvironmentFromUrl, useCustomizations } from "@personicom/customizations";

const APP_INSIGHTS_KEYS: Record<string, string> = {
  development: "637aa50b-6e68-4d17-959a-5bf190c74c3f", //"36326ada-4508-494f-a361-38ede9aae97e",
  localhost: "637aa50b-6e68-4d17-959a-5bf190c74c3f", //"36326ada-4508-494f-a361-38ede9aae97e",
  qa: "637aa50b-6e68-4d17-959a-5bf190c74c3f", //"36326ada-4508-494f-a361-38ede9aae97e",
  production: "637aa50b-6e68-4d17-959a-5bf190c74c3f"
};

export const getAppInsightsKey = () => {
  const environment = getEnvironmentFromUrl();
  const appInsightsKey = APP_INSIGHTS_KEYS[environment];
  return appInsightsKey;  
}