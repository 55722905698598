import { useCustomizations } from "@personicom/customizations";
import { usePlayerAppInsights } from "features/appinsights-provider";
import { getAppInsightsKey } from "helpers/appinsights-helpers";
import { useEffect } from "react";

const AppInsightsWatcher = () => {
  const { isInitialized, tracking } = useCustomizations();
  const { initialize: initializeAppInsights, isTracking: isAlreadyTracking } = usePlayerAppInsights();

  useEffect(() => {
    if(isInitialized){  //make sure we're initialized
      const skipTracking = !!tracking?.noTracking;  //should we be tracking?
      if(!skipTracking && !isAlreadyTracking){  //if we are ok to track, and we're not already tracking, then initialize it.
        const key = getAppInsightsKey();
        initializeAppInsights(key, skipTracking);
      }
    }
  }, [isInitialized, tracking, isAlreadyTracking, initializeAppInsights]);
  

  return (<></>);
};

export default AppInsightsWatcher;