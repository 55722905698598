import { arrayRange } from "helpers/array-helpers";
import { months } from "helpers/date-helpers";
import { isString } from "helpers/utils";
import { CSSProperties } from "react";
import { FormDateOption, FormFieldOption, IComponentLayout, IFormField, IGridItemLayout } from "./form-types";

///====
/// Creates a styl the can be used to place a component in a CSS Grid
export const createFieldStyle = (layout: IComponentLayout | undefined, isMobile: boolean) => {
  if(isMobile && layout?.mobile) return layout.mobile;
  else if(layout?.grid) return createGridStyle(layout.grid);
  else if(layout?.flex) return layout.flex;
  return {};  //no custom layout to apply  
}

///====
/// Creates a styl the can be used to place a component in a CSS Grid
export const createGridStyle = (gridLayout?: IGridItemLayout) => {
  if(!gridLayout) return {};
  
  //NOTE: if the 'area' property doesn't have a /, then assume a single-column grid with 'area' as the row.
  const area = gridLayout.area ? gridLayout.area.indexOf("/") === 0 ? `${gridLayout.area} / 1 / ${gridLayout.area} / 1` : gridLayout.area : undefined;
  const column = gridLayout.gridColumn ? gridLayout.gridColumn : undefined;

  const style = {
    display: "grid",
    gridArea: area,
    gridColumn: column,
  } as CSSProperties;

  if (gridLayout.align) style.alignContent = gridLayout.align;
  if (gridLayout.justify) style.justifyContent = gridLayout.justify;

  return style;
}

// export const createFlexStyle(flexLayout?: CSSProperties)

//===
//Converts a string into a FormFieldOption with all the properties set to the string value
// includes the option to set a different id
const stringToOption = (str: string, value?: any) : FormFieldOption => ({id: value ?? str, value: value ?? str, label: str});

// Builds the array of options for the select
// Can handle the different option types that are available: string[], FormFieldOption[], FormDateOption
// and will also deal with undefined (by returning an empty array)
export const createSelectOptions = (config : undefined | string[] | FormFieldOption[] | FormDateOption) : FormFieldOption[] => {
  if(!config) return [];
  if(Array.isArray(config)){
    //is it already an array of form field options?
    if(!isString(config[0])) return config as FormFieldOption[];
    //otherwise, need to convert strings into FormFieldOptions
    else return config.map<FormFieldOption>(s => stringToOption(s as string));

  }
  else{
    //This is a date options type, return options built from the date properties
    switch(config.dateType){
      case "month":
        let i = 1;  //Start with 1 since months are 1-based
        const values = months.map(m => stringToOption(m, i++));
        return values;

      case "day":
        //TODO: add functionality to find the month value, and limit the number of days based on the month
        const days = arrayRange(1, 31, n => n.toString());
        return days.map(d => stringToOption(d));

      case "year":
        const start = config.minYear ?? 2000;
        const end = config.maxYear ?? (new Date()).getFullYear();
        const years = arrayRange(start, end, n => n.toString());
        return years.reverse().map(y => stringToOption(y));
    }
  }
}


//====
// Validates an array of fields and returns an object with the error information for any 
// invalid fields, or null if there are no errors. 
const ignoreTypes = ["label"];
const groupTypes = ["row"];

export const validateFields = (fields: IFormField[], values: Record<string, any>, idPrefix = "") : Record<string, any> | null => {
  const errors = fields.reduce<Record<string, any>>((result, field) => {
    
    //construct the key used for this field, recognizing groupings which are nested
    const errorKey = `${idPrefix}${idPrefix ? "." : ""}${field.id}`;
    
    //certain types aren't validated (e.g. labels)
    if(ignoreTypes.indexOf(field.type) >= 0 ) return result;

    //certain types are groups, and therefore, we need to recursively check their children
    else if(groupTypes.indexOf(field.type) >= 0){
      if(!field.children) return result; 
      else {
        //recursively call this function for the children
        result = {
          ...result,
          ...validateFields(field.children, values, errorKey), 
        }
      }
    }

    else if(field.isRequired && !values[field.id]){
      result = {
        ...result, 
        hasErrors: true, 
        [field.id]: `${field.label.replace(":", "")} is required`
      };
    }

    return result;
  }, {hasErrors: false});

  return errors.hasErrors ? errors : null;
}