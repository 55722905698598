import { matchPath } from 'react-router-dom';
import { IPlayerProps, PlayerUrlParams, VideoMetadata } from './../../player-types';
import ReactGA from 'react-ga';
import { useEffect, useState } from "react";
import { logApiError } from 'helpers/api-helpers';
import Handlebars from "handlebars";
import { isObject } from 'helpers/utils';
import { IFlatCustomizations } from '@personicom/customizations';
import { getAppInsights } from 'helpers/appInsights';

Handlebars.registerHelper('coalesce',
  function coalesce(...params) {
    // Ignore the object appended by handlebars.
    if (isObject(params[params.length - 1])) {
      params.pop();
    }

    for (let i = 0; i < params.length; i++) {
      if (params[i]) {
        return params[i];
      }
    }

    return params.pop();
  }
);

Handlebars.registerHelper('ifx',
  function ifx(condition, value1, value2) {
    // Check if user has omitted the last parameter
    // if that's the case, it would be the Handlebars options object
    // which it sends always as the last parameter.
    if (isObject(value2) && value2.name === 'ifx' && Object.prototype.hasOwnProperty.call(value2, 'hash')) {
      // This means the user has skipped the last parameter,
      // so we should return an empty string ('') in the else case instead.
      value2 = '';
    }

    return condition ? value1 : value2;
  }
);


// const curlyMatcher  = /(\{\{.*?}})/g;
const hashMatch = /#/g;
const badHashMatch = /%2523/g;

export const openInNewTab = (url:string | undefined) => {
  if (!url)
  {
    return;
  }

  //Add scheme if missing
  if (!/^([a-z][a-z0-9+\-.]*):/i.test(url)) {
    url = 'http://' + url;
  }

  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}

export function getParamsFromSearch(search: URLSearchParams, lcaseParamKeys: string[]) {
  let result: Record<string, string> = {};
  const hasSid = lcaseParamKeys.indexOf("sid") >= 0;

  for (var key of search.keys()) {
    if (lcaseParamKeys.indexOf(key.toLowerCase()) >= 0) {
      result[key] = search.get(key) as string;
      // value = search.get(key);
    }
    //SID might go by a couple different names
    else if (hasSid && key.toLowerCase() === "videoid") {
      result.sid = search.get(key) ?? "";
    }
    if (hasSid && key.toLowerCase() === "renderid") {
      result.sid = search.get(key) ?? "";
    }

  }
  return result;
}

//----
//Gets whether or not the Player is embeded, via the /_embed path part.
export function getIsEmbeded() {
  return window.location.pathname.indexOf("/_embed/") >= 0;
}

//----
//Checks the url search string to see if we're in debug mode. (e.g. search includes &debug=true)
export function getIsDebug(){
  const searchProps = getParamsFromSearch(new URLSearchParams(window.location.search), ["debug"]);
  const isDebug = searchProps.debug?.toLowerCase() === "true";
  return isDebug;
}

//----
// Gets the VideoId, either from the url path, or from the search string, if it's not 
// present in the path.
// NOTE: the match paths need to be kept in sync with the route paths in app.tsx.
// NOTE: This probably won't work when react-router is upgraded to v6. The match doesn't work from above the actual route, and can't do matches against arrays
export function getVideoId(): string | null {
  const match = matchPath<PlayerUrlParams>(window.location.pathname, {
    path: [
      "/:subdomain/_embed/:campaignId/:videoId",
      "/:subdomain/_embed/:videoId", 
      "/:subdomain/:campaignId/:videoId", 
      "/:subdomain/:videoId", 
      "/:subdomain/", 
    ],
    exact: false,
    strict: false
  });
  // let subdomain = match?.params.subdomain  //use the subdomain from the customizations provider for consistency
  let videoId = match?.params.videoId;

  if(!videoId){
    const searchProps = getParamsFromSearch(new URLSearchParams(window.location.search), ["sid"]);
    videoId = videoId ?? searchProps.sid;
  }

  return videoId;
}

interface IDownloadProps {
  school: string;
  sid: string;
  downloadUrl: string;
  isDebug: boolean;
};

export interface IVideoProps {
  isAuthRequired?: boolean;
  isError: boolean;
  error?: string;
  data?: VideoMetadata;
};

const getHostEnvironmentPrefix = (environment: string) => {
  switch (environment?.toLocaleLowerCase()) {
    case "production": return "";
    case "development": return "/QA";  //TODO: UNHACK Dev, or atleast render some items to it.
    case "qa": return "/QA";
    default: return "";
  };
}

const extensionRegex = /\.[a-zA-Z0-9]+$/;

export function getMediaUrl(environment: string, subdomain: string, mediaPath: string, fileName: string): string {
  const hostEnvironmentPrefix = getHostEnvironmentPrefix(environment);
  const extension = extensionRegex.test(fileName) ? "" : ".json";   //check to see if an extension was included with the filename, otherwise assume .json
  const metadataLookupUrl = `${mediaPath}${hostEnvironmentPrefix}/Metadata/${subdomain.toLowerCase()}/${fileName.toLowerCase()}${extension}`;
  console.log("Media lookup url:" + metadataLookupUrl);
  return metadataLookupUrl;
}

//---
// Backwards Compatibility: Call API
// Going forward, read videoId.json from metadata file.
export async function getVideoDetails(client: string, subdomain: string, environment: string, mediaPath: string, videoId: string): Promise<IVideoProps> {
  try {
    var hostEnvironmentPrefix = "";
    switch (environment.toLocaleLowerCase()) {
      case "production":
        hostEnvironmentPrefix = "";
        break;
      case "development":
        hostEnvironmentPrefix = "/QA";  //TODO: UNHACK Dev, or atleast render some items to it.
        break;
      case "qa":
        hostEnvironmentPrefix = "/QA";
        break;
      default:
        hostEnvironmentPrefix = "";
    };

    let theVideoMetadata = {} as VideoMetadata;

    const metadataLookupUrl = `${mediaPath}${hostEnvironmentPrefix}/Metadata/${subdomain.toLowerCase()}/${videoId.toLowerCase()}.json`;
    console.log("Metadata lookup:" + metadataLookupUrl);
    var response = await fetch(metadataLookupUrl);
    var validMetadata = false;
    if (response.ok && response.json) {
      //check version
      const data = await response.json();
      theVideoMetadata = data as VideoMetadata;
      //TODO: Implement semver checking using npm package.
      if (theVideoMetadata.version === "2.0.0") {
        validMetadata = true;
        console.log("VideoMetadata loaded from (BLOB): " + metadataLookupUrl);
      }
    }

    if (!validMetadata) {
      //Fetch from API.
      const url = buildVideoUrl(client, subdomain, videoId);
      console.log("VideoMetadata loaded from (API): " + url);
      response = await fetch(url);
      if (!response.ok || !response.json) {
        throw response;
      }
      const data = await response.json();
      theVideoMetadata = data as VideoMetadata
    }

    console.log(theVideoMetadata);

    // window.$renderId = data.renderId; //Lazy hack, redux me or Context or something.
    return {
      isError: false,
      data: theVideoMetadata
    };
  }
  catch (ex: any) {
    logApiError(ex);
    return {
      isError: true,
      error: `${ex.status}: ${ex.statusText}`,
    };
  }
}

//TODO: should this remain the videoIdFromUrl, or should we switch to the videoId / videoAk from the videoProps
//-------
// Hook to manage the properties pulled from the url
export function useDownloadProperties(subdomain: string, playerProps: IPlayerProps, videoUrl: string | null = null) {
  const [props, setProps] = useState<IDownloadProps>({} as IDownloadProps);

  useEffect(() => {
    setProps({
      school: subdomain,
      sid: playerProps.videoIdFromUrl ?? "",
      downloadUrl: videoUrl ?? `${window.location.origin}/api/common/DownloadVideo/${subdomain}/${playerProps.videoIdFromUrl}`,
      isDebug: playerProps.isDebug,
    });

  }, [subdomain, videoUrl, playerProps.isDebug, playerProps.videoIdFromUrl]);

  return props;
}


//------
// Checks to see if there are any variables in the url (denoted by {{double-curly braces}})
//  and if so, swaps it out for a variable value.
export function createUrl(item: string, config: any, playerProps: Partial<IPlayerProps>, videoMetaData?: VideoMetadata) {

  //For backwards compatibility, check the layout.json version, and if it's 2.0.0, use the new handlebar system.
  if (playerProps.layoutVersion === "2.0.0") //TODO: Symver me
  {
    console.log("templateString:", item);
    return urlFixer(templateString(item, playerProps, videoMetaData));
  }

  let curlyMatcher = /(\{\{.*?}})/g;
  let value = item;
  var match = curlyMatcher.exec(item);
  while (match !== null) {
    //console.log(match[0]);
    value = swapUrlMatch(value, match[0], config, playerProps);
    match = curlyMatcher.exec(item);
  }

  //HACK: run a 2nd time out the above output.
  let curlyMatcher2 = /(\{\{.*?}})/g; //just to be sure i guess
  let staticInputString = value;
  match = curlyMatcher2.exec(value);
  while (match !== null) {
    value = swapUrlMatch(value, match[0], config, playerProps);
    match = curlyMatcher.exec(staticInputString);
  }

  const encoded = encodeURI(value);
  const final = encoded.replace(badHashMatch, "%23").replace(hashMatch, "%23");  //deal with the hashtag, if necessary
  return final;
}

//TODO: should this remain the videoIdFromUrl, or should we switch to the videoId / videoAk from the videoProps
//----
// Does the swap of the match for the variable value.
function swapUrlMatch(value: string, match: string, config: any, playerProps: Partial<IPlayerProps>) {
  const matchKey = match.replace("{{", "").replace("}}", "");
  let swap = "";
  const additionalProps = getParamsFromSearch(new URLSearchParams(window.location.search), ["cl", "en", "parentwindow", "parentreferrer"]);

  if (matchKey === "url") {
    swap = additionalProps.parentWindow ? decodeURIComponent(additionalProps.parentWindow) : window.location.href;
  }
  else if (matchKey === "sid") {
    // let { sid } = getPlayerParamsFromUrl();
    swap = playerProps.videoIdFromUrl ?? "";
  }
  else if (matchKey === "origin") {
    swap = window.location.origin;
  }
  else if (matchKey === "cl") {
    swap = additionalProps.cl;
  }
  else if (matchKey === "en") {
    swap = additionalProps.en;
  }
  else if (matchKey === "parentWindowUrl") {
    swap = additionalProps.parentWindow ? decodeURIComponent(additionalProps.parentWindow) : "";
  }
  else if (matchKey === "parentReferrerUrl") {
    swap = additionalProps.parentReferrer ? decodeURIComponent(additionalProps.parentReferrer) : "";
  }
  else if (matchKey === "currentUrl") {
    swap = window.location.href;
  }
  else if (matchKey === "parentwindow") {
    swap = decodeURIComponent(additionalProps.parentWindow);
  }
  else {
    if (playerProps.variables || config?.VARIABLES) {
      swap = playerProps.variables ? playerProps.variables[matchKey] : config.VARIABLES[matchKey];
    }
  }

  return value.replace(match, swap);

}


/// TODO: Move this to customization, take variable number of objects, and merge them together.
export function templateString(template: string, playerProps: Partial<IPlayerProps>, videoMetaData?: VideoMetadata | null) {
  
  let curlyMatcher = /(\{\{.*?}})/g;

  if (!curlyMatcher.exec(template)) {
    //If no handlebars in the string, short-circuit out.
    return template;
  }
  
  const additionalProps = getParamsFromSearch(new URLSearchParams(window.location.search), ["cl", "en", "parentwindow", "parentreferrer"]);
  const data = {
    url: additionalProps.parentWindow ? decodeURIComponent(additionalProps.parentWindow) : window.location.href,
    sid: playerProps.videoIdFromUrl ?? "",
    origin: window.location.origin,
    cl: additionalProps.cl,
    en: additionalProps.en,
    parentWindowUrl: additionalProps.parentWindow ? decodeURIComponent(additionalProps.parentWindow) : "",
    parentReferrerUrl: additionalProps.parentReferrer ? decodeURIComponent(additionalProps.parentReferrer) : "",
    currentUrl: window.location.href,
    parentwindow: decodeURIComponent(additionalProps.parentWindow),
    ...playerProps,
    ...videoMetaData,
  };

  const compileTemplate = Handlebars.compile(template);
  var result = compileTemplate(data);

  //HACK: Hackish, we can have templates that contain vairables with curly braces, so we loop until they are filled in. We 
  //limit this to 5 loops, in case we have bad template data.

  let i = 0;
  while (curlyMatcher.exec(result) && i < 5) {
    i++;
    const nestedTemplate = Handlebars.compile(result);
    result = nestedTemplate(data);
  }

  return result;
}

export function urlFixer(url: string) {
  const encoded = encodeURI(url);
  const final = encoded.replace(badHashMatch, "%23").replace(hashMatch, "%23");  //deal with the hashtag, if necessary
  return final;
}


//----
//A hook to do the downloading for the footer
export function useVideoDownload(urlProps: IDownloadProps): [(e: any) => void, boolean, number] {
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadPct, setDownloadPct] = useState(0);

  // console.log("urlProps (inside useVideoDownload)", urlProps);

  async function onStart(e: any) {
    if (isDownloading) return;
    setIsDownloading(true);
    setDownloadPct(0);

    e.preventDefault();
    //HACK: Client side trickery to force the download. Otherwise we need to stream through the API with content-disposition headers.
    //Slightly complicated by the fact the blob service returns data as a "206 Partial Content" status.

    console.log("Downloading video...");
    // console.group("Downloading video...");
    const response = await fetch(urlProps.downloadUrl, { redirect: 'follow' });
    if (!response.headers || !response.body) return;

    //TODO: Deal with 404: Not Found
    const contentLength = +(response.headers.get('Content-Length') ?? 0);  //Total file size
    const reader = response.body.getReader();   //Get's a ReadStream
    let receivedLength = 0; // how much we've received so far.
    let chunks = []; // array of chunks

    while (true) {
      const { done, value } = await reader.read();
      if (done || !value) {
        break;
      }
      chunks.push(value);
      receivedLength += value.length;

      var perc = parseInt(((receivedLength / contentLength) * 100).toFixed(0));

      // console.log("download percent:", perc);
      //console.log(`Received (${perc}%) ${receivedLength} of ${contentLength}`)            
      setDownloadPct(perc);
    }
    // console.groupEnd();

    const fileName = `${urlProps.school}_${urlProps.sid}.mp4`;
    forceDownload(new Blob(chunks), fileName);

    ReactGA.event({
      category: 'ClickLandingPageButton',
      action: "Download",
      label: `${urlProps.school}:${urlProps.sid}`
    }, ['pstracker']);

    setIsDownloading(false);
    setDownloadPct(0);
  }


  return [onStart, isDownloading, downloadPct];
}


function forceDownload(blob: any, fileName: string) {
  let url = window.URL.createObjectURL(blob);
  let a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  a.click();
}

const LOCAL_URL_BASE = "http://localhost:7072/api";

export const getApiBaseUrl = (endpoint: string = "video") => {
  const isLocalHost = Boolean(window.location.hostname.indexOf("localhost") >= 0);
  if (isLocalHost) {
    return endpoint ? `${LOCAL_URL_BASE}/${endpoint}` : LOCAL_URL_BASE;
  }
  else {
    return endpoint ? `/api/${endpoint}` : "/api";
  }
}

export function buildVideoUrl(rootdomain: string, subdomain: string, sid: string) {
  const url = `${getApiBaseUrl()}/${rootdomain}/${subdomain}/${sid}`;
  // let url = "";
  // if(!campaign) url = `${getApiBaseUrl()}/${rootdomain}/${subdomain}/${sid}`;
  // else url = `${getApiBaseUrl()}/${rootdomain}/${subdomain}/${campaign}/${sid}`;

  return url;
}

// Call our API function to track key events.
// export function trackEvent( eventName: string, customizations: IFlatCustomizations, videoProps: VideoMetadata | null, additionalProps?:any){


//   const { isInitialized, tracking } = customizations;
//   if(!isInitialized || tracking?.noTracking){
//     console.log("no tracking is set, not tracking any AI events.");
//   }

//   var eventData         = {  
//     environmentName: customizations.environment,
//     rootDomain: videoProps?.rootDomain,
//     subdomain: videoProps?.subdomain,
//     renderId: videoProps?.videoId,
//     campaignId: videoProps?.campaignId,
//     videoAk: videoProps?.videoAk,
//     operationId: videoProps?.operationId,
//     SID: videoProps?.videoAk 
//   };

//   if(additionalProps)
//   {
//     eventData = {...eventData, ...additionalProps};
//   }
//   //For now, back to AI:
//   getAppInsights().trackEvent({ name: eventName }, eventData);

//   /*
//   const url = `${getApiBaseUrl("")}/track`;  
//   //SID: this.props.videoId,
//   const telemetryObject =   { 
//     eventName: eventName,
//     instrumentationKey: getAppInsights().config.instrumentationKey,
//     properties:{
//       environmentName: customizations.environment,
//       rootDomain: videoProps?.rootDomain,
//       subdomain: videoProps?.subdomain,
//       renderId: videoProps?.videoId,
//       campaignId: videoProps?.campaignId,
//       videoAk: videoProps?.videoAk,
//       operationId: videoProps?.operationId,
//       SID: videoProps?.videoAk
//     },
//     metrics:{

//     }
//   }

//   if(additionalProps)
//   {
//     telemetryObject.properties = {...telemetryObject.properties,...additionalProps};
//   }

//   if(additionalMetrics)
//   {
//     telemetryObject.metrics = {...telemetryObject.metrics,...additionalMetrics};
//   }

//   //Send beacon is fire and forget.
//   navigator.sendBeacon(url, JSON.stringify(telemetryObject));
//   */
// }

//====
// Takes the Customizations and Video Props and builds the PlayerProps structure
// which is used throughout the player.
export function buildPlayerProps(customizations: IFlatCustomizations, videoProps: VideoMetadata | null){
  const isDebug = getIsDebug();    
  const { images, player, blobUrl, subdomain  } = customizations;

  const theProps : Partial<IPlayerProps> = {
    layoutVersion   : customizations.layoutVersion,
    title           : customizations.strings?.header?.title,
    homeUrl         : customizations.links?.homeUrl,
    captionsFile    : player?.captionsFile,
    captionsFileUrl : customizations.player?.captionsFile ? blobUrl(player?.captionsFile) : null,
    faviconUrl      : customizations.images?.favicon ? blobUrl(images?.favicon) : null,
    waitMessage     : customizations.strings?.body?.waitMessage,
    errorMessage    : customizations.strings?.body?.errorMessage,
    staticThumbnailUrl  : customizations.player?.staticThumbnail ? blobUrl(player?.staticThumbnail) : null,
    logoUrl         : blobUrl(customizations.player?.logoUrl ?? customizations.images?.logo),
    brandUrl        : customizations.player?.brandUrl,
    brandName       : customizations.player?.brandName,
    brandingFlexBias  : customizations.player?.brandingFlexBias,
    stylesEmbeded   : customizations.player?.embededStyles ? blobUrl(customizations.player?.embededStyles) : null,
    variables       : customizations.variables,
    shareOptions    : customizations.player?.shareOptions,
    interactiveParameters  : customizations.player?.interactiveParameters,
    subdomain       : subdomain,
    videoIdFromUrl  : videoProps?.videoId ?? undefined,
    isDebug         : isDebug
  };

  return theProps;
}