import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReactGA from 'react-ga';
import { ThemeEx, FooterButtonProps, IPlayerProps } from "../../player-types";
import { useDownloadProperties, useVideoDownload } from '../../features/player/player-helpers';
import { useCustomizations } from "@personicom/customizations";
import { usePlayerContext } from 'features/context/player-context-hooks';
import { useTrackPlayerEvent } from 'features/appinsights-provider';

const EVENT_CATEGORY = "ClickLandingPageButton";

const DownloadButton = ({ playerProps, buttonConfig }: FooterButtonProps) => {
  const customizations = useCustomizations();
  const trackEvent = useTrackPlayerEvent();
  const { flags, subdomain, footer } = useCustomizations();
  const { videoProps } = usePlayerContext();
  const classes = buildStyles({footer});
  const urlInfo = useDownloadProperties(subdomain, playerProps as IPlayerProps, videoProps?.videoUrl);
  const [onStartDownload, isDownloading, downloadPercent]  = useVideoDownload(urlInfo);
  
  function onClick(e: any){
    if(!isDownloading)
    {
      onStartDownload(e);
      const buttonType = e.currentTarget.id || e.currentTarget.title || "FooterDownloadButton";
      const aiEvent         = { buttonType: buttonType, buttonUrl: e.currentTarget.href };
      const gaEvent         = { category: EVENT_CATEGORY, action: "download", label: `${playerProps.subdomain}:${videoProps?.videoAk ?? videoProps?.videoId}` };

      trackEvent(EVENT_CATEGORY, customizations, videoProps, aiEvent);
      ReactGA.event(gaEvent, ['pstracker']);
    }
  }

  if(isDownloading){
    return (
      <Grid item className={classes.downloadProgressGrid} title="Downloading video">
        <CircularProgress size={52.5} variant="static" value={downloadPercent} />
        <i className={clsx("fa fa-download", flags.faShareButtonSizeOverride , classes.progressIcon)}/>
      </Grid>
    );
  }
  else{
    return (
      <button onClick={onClick} className={classes.downloadButton} title={buttonConfig.tip}>
        <i className={clsx("fa fa-download", flags.faShareButtonSizeOverride)}/>
      </button>
    );
  }
};

export default DownloadButton;

const buildStyles = makeStyles((theme: ThemeEx) => ({
  downloadButton: {
    margin: theme.styles?.footer?.socialRow?.button?.margin ?? "none",
    borderRadius: 57,
    borderWidth: 0,
    boxShadow: theme.styles?.footer?.socialRow?.button?.boxShadow ?? "0px 0px 2px #888",
    padding: "0.2em 0.25em",
    fontSize: 35,
    height: 52.5,
    width: 52.5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textDecoration: "none",
    cursor: "pointer",
    backgroundColor: theme.styles?.footer?.socialRow?.button?.backgroundColor ?? theme.styles?.footer?.button?.backgroundColor,
    color: theme.styles?.footer?.socialRow?.button?.color ?? theme.styles?.footer?.button?.color ?? theme.styles?.footer?.color,
    [theme.breakpoints.down('xs')]: {
      height: 36,
      width: 36,
      fontSize: 16,
    },
  },
  downloadProgressGrid: {
    position          : "relative",
    marginBottom      : -5,
    // width             : 52.5,
    textAlign         : "center",
  },
  progressIcon      : {
    fontSize          : 35,
    position          : "absolute", 
    left              : "20%",
    top               : "15%",
    color             : theme.styles?.footer?.button?.backgroundColor,
  },  
}));