import { useContext } from "react";
import { IPlayerContext } from '../../player-types';
import { PlayerContext } from './player-provider';

export function usePlayerContext(){
  const ctx = useContext<IPlayerContext | null>(PlayerContext);
  return {
    playerProps: ctx?.playerProps!,
    videoProps: ctx?.videoProps ?? null,
  };
}

export function usePlayerProperties(){
  const ctx = usePlayerContext();
  return ctx?.playerProps ?? null;
}

export function useVideoProperties(){
  const ctx = usePlayerContext();
  return ctx?.videoProps ?? null;
}