import { Grid } from "@material-ui/core";
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import CustomizationsProvider, { CustomizationHelmet } from "@personicom/customizations";
import { PlayerProvider } from "features/context/player-provider";
import AppBody from "./app-body";
import AppHeader from "app-header";
import { AuthProvider } from "features/authentication/auth-provider";
import AppInsightsProvider from "features/appinsights-provider";
import AppInsightsWatcher from "components/appinsights-watcher";

const GA_TRACKER = "UA-102377011-8";
const GA_TRACKER_SECONDARY = "UA-157273197-1";    //Fallback, in case it's not found in the layout.json

const App = () => {
  
  return (
    <CustomizationsProvider>
      <CustomizationHelmet gaTrackerId={GA_TRACKER} gaTrackerSecondaryId={GA_TRACKER_SECONDARY} />
      <AuthProvider>
        <AppInsightsProvider>
          <PlayerProvider>
            <AppInsightsWatcher />
            <Grid id="app-container" container>
              <AppHeader />          
              <Router>
                <Switch>
                  {/* NOTE: if a new path is added that includes :videoId, need to add it to the getVideoId function in /features/player/player-helpers.ts */}
                  <Route exact path="/:subdomain/" component={AppBody} />
                  <Route exact path="/:subdomain/:videoId" component={AppBody} />
                  <Route exact path="/:subdomain/:campaignId/:videoId" component={AppBody} />
                  <Route exact path="/:subdomain/_embed/" component={AppBody} />
                  <Route exact path="/:subdomain/_embed/:videoId" component={AppBody} />
                  <Route exact path="/:subdomain/_embed/:campaignId/:videoId" component={AppBody} />
                  <Route path="/" component={AppBody} /> {/* fallback route, pulls options from search string */}
                </Switch>
              </Router>
            </Grid>
          </PlayerProvider>
        </AppInsightsProvider>
      </AuthProvider>
    </CustomizationsProvider>
  );
}

export default App;
