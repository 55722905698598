import { useDynamicProps, useFieldConfig, useFormContext } from "./form-context";
import { KeyboardDatePicker, KeyboardDatePickerProps } from "@material-ui/pickers";
import { Chip } from "@material-ui/core";

interface IRemoveFromProps {
  id: string | undefined;
  value: any;
  onChange: any;
}

export interface IFormDatePickerProps extends Omit<KeyboardDatePickerProps, keyof IRemoveFromProps> {
  id: string;
  dynamicProps?: Record<string, Record<string, any>>;
}

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

const FormDatePicker = ({id, ...otherProps}: IFormDatePickerProps) => {
  const { values, errors, updateValue } = useFormContext();
  const { fieldConfig, dynamicProps } = useFieldConfig(id);

  const handleDatePickerChange = (date: Date | null) => {
    updateValue(fieldConfig!.id, date);
  }

  if(!fieldConfig){
    return (<Chip
      label={`no config for field: ${id}`}
      color="secondary"
      variant="outlined"
    />);
  }

  return (
    <KeyboardDatePicker
      // clearable
      value={values.dob}
      error={!!errors.dob}
      helperText={errors.dob}
      label={fieldConfig.label}
      placeholder={fieldConfig.placeholder ?? "12/31/2010"}
      onChange={date => handleDatePickerChange(date as Date)}
      {...otherProps}
      {...fieldConfig.componentProps}
      {...dynamicProps}
    />
  );
};

export default FormDatePicker;