import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useFieldConfig } from "./form-context";
import { createGridStyle } from "./form-utils";
import FormField from "./form-field";

const buildStyles   = makeStyles(theme => ({
  row: (props: any) => ({
    display: "grid",
    width: "100%",
    columnGap: "1em",
    ...props,
  }),
}));

export interface IFormRowProps {
  id: string;
}

const FormRow = ({id}: IFormRowProps) => {
  const { fieldConfig, dynamicProps } = useFieldConfig(id);
  
  const classes   = buildStyles({...fieldConfig?.componentClasses});
  //TODO: handle the children fields for useFieldConfig e.g. dobRow.dobMonth
  return (
    <div className={classes.row}>
      {fieldConfig?.children?.map(field => {
          return (
            <div 
              id={`${field.id}-div`} 
              key={field.id} 
              style={createGridStyle(field.grid)}>
                <FormField fieldConfig={field} idPrefix={fieldConfig.id}/>
            </div>
          )
        })}
    </div>
  );
}

export default FormRow;
