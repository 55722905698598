import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';
import ReactGA from 'react-ga';
import Favicon from 'react-favicon';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { logApiError } from "helpers/api-helpers";
import { Player, ControlBar, BigPlayButton, ClosedCaptionButton, PlayToggle, VolumeMenuButton, CurrentTimeDisplay, TimeDivider, DurationDisplay, ProgressControl, FullscreenToggle } from 'video-react';
import PlayerBranding from './player-branding';
import ControlBarButton from './control-bar-button';
import SharePanel from './share-panel';
import PlayerElements, { PlayerElement, KeyFrame } from './player-elements.js'
import { urlFixer, templateString } from 'features/player/player-helpers';
import 'react-circular-progressbar/dist/styles.css';
import './player.css';

const shareButton = {
  id: "share",
  type: "share-button",
  icon: "fa fa-share-alt app-icon",
  tip: "Share this video"
};

const downloadButton = {
  id: "download",
  type: "download-button",
  icon: "fa fa-download app-icon",
  tip: "Download video",
};

const DEFAULT_STATE = {
  hasBegun: false,
  hasEnded: false,
  nextTick: null,
  videoViewedTotalSeconds: 0,
  isLoaded: false,
  error: null,
  renderId: null,
  videoId: null,
  videoAk: null,
  brandingTemplate: null,
  videoUrl: null,
  thumbnailUrl: null,
  animatedThumbnailUrl: null,
  posterUrl: null,
  interactiveParameters: null,
  fps: 30,
  closedCaptionTrack: null,
  closedCaptions: false,
  isSharing: false,
}

export class PersonicomPlayer extends React.Component {
  playerWrap = React.createRef();

  constructor(props, context) {
    super(props, context);
    if (this.props.isEmbeded === true) {
      //Make sure we don't have scrollbars
      const htmlEle = document.getElementsByTagName("html")[0];
      htmlEle.style.overflow = "hidden";
    }

    //TODO: don't really need to copy all these values to the state, can just access them from props.videoProps...
    this.state = {
      ...DEFAULT_STATE,
      isDebug: this.props.playerProps.isDebug
    };
    this.player = null;
    this.loggingRateInSeconds = 5;
    this.onShareClick = this.onShareClick.bind(this);
  }

  async componentDidMount() {
    console.log('Player Render.');
    const data = this.props.videoProps;

    if (!data.frameRate) {
      console.warn("frameRate not specified, defaulting to 30.");
    }
    //TODO: don't really need to copy all these values to the state, can just access them from props.videoProps...
    this.setState({
      renderId: data.videoId,   //videoProps provides the renderId from the server
      videoId: data.videoId,
      videoAk: data.videoAk ?? data.videoId,    //this is what was formerly called the SID, and is different from the videoId (which was formerly called renderId)
      videoUrl: data.videoUrl,
      thumbnailUrl: data.thumbnailUrl,
      animatedThumbnailUrl: data.animatedThumbnailUrl,
      fps: data.frameRate ?? 30
    });
    if (this.props.playerProps.captionsFileUrl) {
      this.loadCloseCaptioning();
    }

    this.setState({
      isLoaded: true
    });
    this.logState();  //DEBUG
  }

  handleStateChange(playerState) {
    var totalViewTimeSeconds = this.state.videoViewedTotalSeconds;
    const customizations = this.props.customizations;
    const videoProps = this.props.videoProps;

    if (!this.state.hasBegun) {
      if (playerState.hasStarted === true) {
        var nextTickMs = (Date.now() + (this.loggingRateInSeconds * 1000));
        this.setState({ hasBegun: true, nextTick: nextTickMs });

        console.log(`Video.Begin: ${totalViewTimeSeconds}`);
        this.props.trackEvent("Video.Begin", customizations, videoProps)
        ReactGA.event({
          category: 'Video',
          action: 'begin',
          label: `${this.props.subdomain}:${this.state.videoAk}`,
          value: totalViewTimeSeconds
        }, ['pstracker']);

        return;
      }
    } else {
      if (!playerState.paused && (this.state.nextTick <= Date.now())) {
        var nextTickMs = (Date.now() + (this.loggingRateInSeconds * 1000));
        totalViewTimeSeconds = this.state.videoViewedTotalSeconds + this.loggingRateInSeconds;
        this.setState({ nextTick: nextTickMs, videoViewedTotalSeconds: totalViewTimeSeconds });

        console.log(`Video.View: ${totalViewTimeSeconds}`);
        this.props.trackEvent("Video.View", customizations, videoProps, { viewTotalSeconds: totalViewTimeSeconds.toString() }) // our /track api wants all strings.
        this.props.trackEvent("Video.ViewTick", customizations, videoProps, { viewTickAmount: this.loggingRateInSeconds.toString() }) // our /track api wants all strings.

        ReactGA.event({
          category: 'Video',
          action: 'view',
          label: `${this.props.subdomain}:${this.state.videoAk}`,
          value: totalViewTimeSeconds
        }, ['pstracker']);

        ReactGA.event({
          category: 'Video',
          action: 'viewTick',
          label: `${this.props.subdomain}:${this.state.videoAk}`,
          value: this.loggingRateInSeconds
        }, ['pstracker']);

      }
    }

    if (!this.state.hasEnded) {
      if (playerState.hasStarted === true
        && playerState.ended) {
        var endedOn = Date.now();
        var leftOverTicksMs = (this.state.nextTick - endedOn);
        var remainingTickSeconds = Math.round(leftOverTicksMs / 1000);
        var totalViewTimeSeconds = this.state.videoViewedTotalSeconds + remainingTickSeconds;
        this.setState({ hasEnded: true, videoViewedTotalSeconds: totalViewTimeSeconds });
        console.log(`Video.End: ${totalViewTimeSeconds}`);
        this.props.trackEvent("Video.End", customizations, videoProps, { viewTotalSeconds: totalViewTimeSeconds.toString })// our /track api wants all strings.
        ReactGA.event({
          category: 'Video',
          action: 'end',
          label: `${this.props.subdomain}:${this.state.videoAk}`,
          value: totalViewTimeSeconds
        }, ['pstracker']);
      }
    }
  }

  logState() {
    console.log('brandUrl: ' + this.props.playerProps.brandUrl);
    console.log('logoUrl: ' + this.props.playerProps.logoUrl);
    console.log("posterUrl: " + this.props.playerProps.staticThumbnailUrl);
    console.log("interactiveParameters: ");
    console.log(this.props.playerProps.interactiveParameters);
    console.log("closedCaptionTrack: " + this.props.playerProps.captionsFileUrl);
  }

  async loadCloseCaptioning() {
    try {
      //Default static captions located in theme-config.
      var ccUrl = this.props.playerProps.captionsFileUrl;

      if (this.props.playerProps.captionsFile.includes("{{")) {
        //Dynamic caption file, in VideoRender blob storage.
        var ccFile = templateString(this.props.playerProps.captionsFile, this.props.playerProps, this.props.videoProps);
        const url = new URL(this.props.videoProps.videoUrl);
        const newPathSegments = url.pathname.split('/');
        newPathSegments[newPathSegments.length - 1] = ccFile;
        url.pathname = newPathSegments.join('/');
        ccUrl = url.toString();
      }
      //This fetch really just decides if it exists and whether to draw the button.
      const ccResponse = await fetch(ccUrl);
      this.setState({ closedCaptions: ccResponse.ok, closedCaptionTrack: ccUrl })
      console.log("VTT File Found:" + ccResponse.ok);

    } catch (ccErr) {
      logApiError(ccErr);
      // console.log(e);
    };
  }

  async onShareClick(e) {
    this.setState({ isSharing: !this.state.isSharing });
  }

  /*
   * While the video runs, this component does not re-render.
   * So hook the time update, and update list of elements with Current Frame.
   */

  render() {
    const customizations = this.props.customizations;
    const { isEmbeded, isMobile, isMicro, playerProps, videoProps } = this.props;
    const { isSharing, closedCaptions, closedCaptionTrack } = this.state;
    const { shareOptions } = playerProps;
    const schoolName = this.props.subdomain;
    const interactiveParams = playerProps.interactiveParameters ?? [];

    if (!this.state.isLoaded || this.state.error) {
      return (
        <div className={classNames(schoolName, { "player-embeded": isEmbeded })}>
          <PlayerPlaceholder playerProps={playerProps} error={this.state.error} />
        </div>
      );
    }

    const listOfElements = interactiveParams.map((iParam, index) => {
      var interactiveClickUrl = "";

      //Sanity check
      if (!iParam.keyFrames) {
        console.warn(`Keyframes missing for interactive param # ${index + 1} `);
        return (
          <React.Fragment></React.Fragment>
        );
      }

      if (iParam.value) {
        //Template it.
        interactiveClickUrl = urlFixer(templateString(iParam.value, this.props.playerProps, this.props.videoProps));
      }

      if (playerProps.isDebug) {
        console.log(`${iParam.name}: ` + interactiveClickUrl)
      }

      return (
        <PlayerElement key={iParam.name} renderId={this.state.renderId} name={iParam.name} videoId={this.state.videoId} value={interactiveClickUrl} subdomain={this.props.subdomain} isDebug={this.state.isDebug} customizations={customizations} videoProps={videoProps} trackEvent={this.props.trackEvent} >
          {iParam.keyFrames.map((frameInfo, i) => (
            <KeyFrame key={frameInfo.frameNumber} frameNumber={frameInfo.frameNumber} cssStyle={frameInfo.cssStyle} noEnd={frameInfo.noEnd} isDebug={this.state.isDebug} />
          ))}
        </PlayerElement>
      )
    });

    let embedSpecificElements = null;
    if (isEmbeded) {
      //TODO: is this necessary / desired when the player is embeded in an iFrame?
      const styles = playerProps.stylesEmbeded ? <style type="text/css">{`@import url(${playerProps.stylesEmbeded});`}</style> : null;
      embedSpecificElements = (
        <Fragment>
          <Helmet>
            <meta charSet="utf-8" />
            <title>{playerProps.title}</title>
            <meta name="description" content={playerProps.title}></meta>
            <link rel="canonical" href={playerProps.homeUrl} />
            <link rel="apple-touch-icon" href={playerProps.faviconUrl} />
            {styles}
          </Helmet>
          <Favicon url={playerProps.faviconUrl} />
        </Fragment>
      );
    }

    //Whether or not the big player button is necessary
    //Using falsy check because empty strings from API call.
    const hideBigButton = (!this.state.animatedThumbnailUrl || !this.state.thumbnailUrl || !playerProps.staticThumbnailUrl);

    return (
      <div className={classNames(schoolName, "player-container", { "player-embeded": isEmbeded })} ref={this.playerWrap} >
        {embedSpecificElements}
        <Player
          ref={player => {
            if (this.player === null) {
              this.player = player;
              this.player.subscribeToStateChange(this.handleStateChange.bind(this));
            }
          }}
          src={this.state.videoUrl}
          poster={this.state.animatedThumbnailUrl || this.state.thumbnailUrl || playerProps.staticThumbnailUrl || undefined}
          playsInline={true}
          fluid={true}
          crossOrigin="anonymous"
        >
          <BigPlayButton position="center" className={ hideBigButton ? "no-big-player-button" : ""} />
          
          <PlayerElements ref="InteractiveElements" fps={this.state.fps} isDebug={this.state.isDebug} trackEvent={this.props.trackEvent}>
            {listOfElements}
          </PlayerElements>
          {closedCaptions &&
            <track
              kind="captions"
              src={closedCaptionTrack}
              srcLang="en"
              label="English"
            />
          }
          <ControlBar autoHide={true} autoHideTime={2000} disableDefaultControls={true}>

            {!isMobile && <PlayToggle order={1} />}
            {!isMobile && <VolumeMenuButton vertical order={4.1} />}
            {!isMicro && <CurrentTimeDisplay key="current-time-display" order={5.1} />}
            {!isMicro && <TimeDivider key="time-divider" order={5.2} />}
            {!isMicro && <DurationDisplay key="duration-display" order={5.3} />}
            <ProgressControl key="progress-control" order={6} />
            <FullscreenToggle key="fullscreen-toggle" order={8} />

            {closedCaptions && <ClosedCaptionButton order={8.1} />}

            <ControlBarButton order={8.2} renderId={this.state.renderId} buttonProps={downloadButton} playerProps={playerProps} customizations={customizations} videoProps={videoProps} videoUrl={this.state.videoUrl} className="controlbar-button" />
            {shareOptions?.length > 0 && <ControlBarButton order={8.3} renderId={this.state.renderId} onClick={this.onShareClick} buttonProps={shareButton} playerProps={playerProps} customizations={customizations} videoProps={videoProps} className="controlbar-button" />}

            {!isMobile &&
              <PlayerBranding
                order={8.4}
                subdomain={this.props.subdomain}
                renderId={this.state.renderId}
                playerProps={playerProps}
                customizations={customizations}
                videoProps={videoProps}
                brandLogoUrl={playerProps.logoUrl}
                brandUrl={playerProps.brandUrl}
                brandName={playerProps.brandName}
                trackEvent={this.props.trackEvent}
              />
            }
          </ControlBar>
        </Player>
        {isSharing && <SharePanel renderId={this.state.renderId} buttons={playerProps.shareOptions} playerProps={playerProps} videoUrl={this.state.videoUrl} onClose={() => this.setState({ isSharing: false })} customizations={customizations} videoProps={videoProps} />}
      </div>
    );
  }
};

export default PersonicomPlayer;

PersonicomPlayer.propTypes = {
  isEmbeded: PropTypes.bool,
  isMobile: PropTypes.bool,
  isMicro: PropTypes.bool,
  playerProps: PropTypes.object,
  videoProps: PropTypes.object,
  customizations: PropTypes.object,
};

function PlayerPlaceholder({ playerProps, error }) {
  return (
    <div className="wait-container">
      {!error && <CircularProgress color="primary" />}
      {!error
        ? <Typography color="primary" component="h2" className="wait-message">{playerProps.waitMessage}</Typography>
        : <Typography color="primary" component="h2" className="wait-message">{error}</Typography>
      }
    </div>
  );
}