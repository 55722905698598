import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { isArray, mergeWith } from "lodash";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { fetchJsonFile, LoadingIndicator, useCustomizations } from "@personicom/customizations";
import { IAuthConfig, IAuthContext, IAuthRequest } from "./auth-types";
// import AuthDialog from "./auth-dialog";
import { doAuthentication } from "./auth-utils";
import AuthForm from "./auth-form-2";

export interface IAuthProviderProps {
  children: ReactNode;
}

export const AuthContext = React.createContext<IAuthContext>({
  isInitialized: false,
  isAuthenticated: false,
  canAuthenticate: false,
  authenticate: () => false,
  authResult: null,
});

const AUTH_FILE_NAME = "config.json";

export const AuthProvider = ({ children }: IAuthProviderProps) => {
  const [isInitialized, setInitialized] = useState(false);
  const [authConfig, setAuthConfig] = useState<IAuthConfig | null>(null);
  const [isAvailable, setIsAvailable] = useState<boolean | undefined>(undefined);
  const [isRequired, setIsRequired] = useState(false);  //only required in certain circumstances
  const [isAuthorized, setAuthorized] = useState(false); 
  const [authResult, setAuthResult] = useState<any>(null); 

  //TODO: deal with errors during authorization
  // const [error, setError] = useState<string | null>(null);
  const { blobRootUrl, blobSubdomainUrl, subdomain, environment, blobPaths } = useCustomizations();

  const needsAuth = useMemo(() => {
    return (isAvailable && isRequired && !isAuthorized);
  }, [isAvailable, isRequired, isAuthorized]);

  //===
  //Check for a config.json file in the client or subdomain path
  useEffect(() => {
    async function doEffect(){
        //1. check for a root-level config.json file
        const rootPath = blobRootUrl(AUTH_FILE_NAME);
        const rootConfig = await fetchJsonFile(rootPath);
        let finalConfig = rootConfig.isError ? null : rootConfig;

        const subdomainPath = blobSubdomainUrl(AUTH_FILE_NAME) as string;
        if(subdomainPath){
          let subdomainConfig = await fetchJsonFile(subdomainPath);
          subdomainConfig = subdomainConfig.isError ? null : subdomainConfig;

          if(subdomainConfig){
            //TODO: Test that if rootConfig is undefined / null, subdomainConfig will still be used
            finalConfig = mergeWith(rootConfig, subdomainConfig, (objValue, srcValue) => {
              if(isArray(objValue)){
                return objValue.concat(srcValue);
              }
            });
          }
        }

        setAuthConfig(finalConfig);
        setIsAvailable(!!finalConfig);
        setInitialized(true); //Flag that we have what we need
    }

    doEffect();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); //run this once, on initialization

  const onAuthChange = async (authInfo: IAuthRequest) => {
    let authResult = await doAuthentication(environment, subdomain, blobPaths.mediaPath, authInfo.values);
    console.log("OnAuthChanged: ", authInfo, authResult);
    
    // //TODO: TESTING
    // authResult.isError = false;
    // authResult.data = TEST_RESULT;

    if(authResult.isError){
      return authResult.error ?? null;
    }
    else{
      //pass along the auth results to the context
      setAuthResult(authResult.data);
      setAuthorized(true);
      return null;
    }
  }

  const onAuthorize = () => {
    if(isAvailable){
      if(!isAuthorized){
        setIsRequired(true);
      }
      return true;
    }
    return false; //not available
  }

  return (
    <AuthContext.Provider value={{
      isInitialized,
      isAuthenticated: isAuthorized,
      canAuthenticate: isAvailable!,
      authenticate: onAuthorize,
      authResult,
    }}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <>
          {!isInitialized && <LoadingIndicator /> }
          {isInitialized && needsAuth && <AuthForm formConfig={authConfig!.plainAuth!} onAuthChange={ onAuthChange } />}
          {isInitialized && !needsAuth && children }
          {/* (
            <>
              {children}
              {needsAuth && <AuthDialog formConfig={authConfig!.plainAuth!} onAuthChange={ onAuthChange } /> }
            </>
          )} */}
        </>
      </MuiPickersUtilsProvider>
    </AuthContext.Provider>
  )
}