import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { createAppInsights } from "./appinsights-config";
import { IAppInsightsContext } from "./appinsights-types";

export interface IAppInsightsProviderProps {
  children: ReactNode;
}

export const AppInsightsContext = React.createContext<IAppInsightsContext | null>(null);

const AIProvider = ({children}: IAppInsightsProviderProps ) => {
  const [noTracking, setNoTracking] = useState<boolean>(true);                //assume no tracking to start
  const [aiKey, setAiKey] = useState<string>("");
  const [ai, setAi] = useState<ApplicationInsights | null>(null);

  //Create Application Insights if tracking is not turned off.
  useEffect(() => {
    if(!noTracking && !ai){   //we are tracking
      const tracker = createAppInsights(aiKey);
      tracker.loadAppInsights();
      setAi(tracker);
      console.log("appinsights started with key ", tracker.config.instrumentationKey);
    }
  }, [noTracking, ai, aiKey]);

  // Function to track an event from the player
  const trackEvent = useCallback((eventName: string, eventData: any) => {
    if(!noTracking){
      ai?.trackEvent({ name: eventName }, eventData);
    }
  }, [ai, noTracking]);


  const initialize = (aiKey: string, isNoTracking: boolean) => {
    setAiKey(aiKey);
    setNoTracking(isNoTracking);
  }

  //The context value to provide to children
  const contextValue = {
    initialize,
    trackEvent,
    isTracking: !noTracking,
  };

  
  return (
    <AppInsightsContext.Provider value={contextValue}>
      {children}
    </AppInsightsContext.Provider>
  );
};

export default AIProvider;