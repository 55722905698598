import React, { ReactNode, useEffect, useState } from "react";
import { IPlayerContext, IPlayerProps, VideoMetadata } from "player-types";
import { ErrorCard, LoadingIndicator, useCustomizations } from "@personicom/customizations";
import { buildPlayerProps, getVideoDetails, getVideoId, IVideoProps } from "features/player/player-helpers";
import { IBlobStorageInfo } from "@personicom/customizations/lib/esm/customization-types";
import { useAuth } from "features/authentication/auth-utils";
import { useTrackPlayerEvent } from "features/appinsights-provider";
export interface IPlayerProviderProps {
  children: ReactNode;
}

///====
/// The context, used by the usePlayerContext hook to get the state for the player-provider
export const PlayerContext = React.createContext<IPlayerContext | null>(null);

//====
// Gets the video id, and the metadata / details of the video
const getVideoProps = async (client: string, subdomain: string, environment: string, blobPaths: IBlobStorageInfo) : Promise<IVideoProps> => {
  const videoId = getVideoId();
  if(videoId){
    const props = await getVideoDetails(client, subdomain, environment, blobPaths.mediaPath, videoId);
    return props;
  }
  else{
    return {
      isAuthRequired: true,
      isError: true,
      error: "No VideoId, authentication required",
    };
  }
};

//===
// The PlayerProvider to wrap the player app. This encapsulates the CustomizationsProvider.
export const PlayerProvider = ({ children } : IPlayerProviderProps) => {
  const [playerProps, setPlayerProps] = useState<IPlayerProps | null>(null);
  const [videoProps, setVideoProps] = useState<VideoMetadata | null>(null);
  const [needsAuth, setNeedsAuth] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const customizations = useCustomizations();
  const { isAuthenticated, canAuthenticate, authenticate, authResult } = useAuth();
  const { client, subdomain, environment, blobPaths, setCampaignName } = customizations;
  const trackEvent = useTrackPlayerEvent();

  //--- Effect 1: get the video props, if we have enough info
  useEffect(() => {
    async function doEffect(){
      const vProps = await getVideoProps(client, subdomain, environment, blobPaths);
      if(vProps.isAuthRequired){
        console.info("No VideoId provided, authentication required to load video.");
        if(canAuthenticate){
          setNeedsAuth(true);
          authenticate();  //trigger authentication
        }
        else{
          console.error("Authentication is required, but not available (no config.json file): ");
          setError("Unable to authenticate for access to the video");  
        }
      }
      else if(vProps.isError){
        console.error("Failed to get video details: ", vProps);
        setError("Failed to get video details");
      }
      else{
        setCampaignName(vProps.data?.campaignName!);
        setVideoProps(vProps.data!);
      }
    }

    if(client && subdomain && environment && blobPaths){
      doEffect();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, subdomain, environment, blobPaths, canAuthenticate]);

  
  //--- Effect 2: Merge Customizations and VideoProps to create the PlayerProps
  useEffect(() => {
    if(customizations){
      const props = buildPlayerProps(customizations, videoProps);
      console.log("Layout Version: " + props.layoutVersion);
      setPlayerProps(props as IPlayerProps);
    }
  }, [customizations, videoProps]);

  //--- Effect 3: Authentication
  useEffect(() => {
    if(needsAuth && isAuthenticated){
      //now we should have the video id
      console.log("authenticated: ", authResult);
      //TODO: Update the campaign file with the customizations provider
      const campaignName = authResult.campaignName;
      setCampaignName(campaignName);
      //TODO: populate the video details with the auth result
      setVideoProps(authResult);
    }
  }, [needsAuth, isAuthenticated, authResult]);

  //----- Effect 4: Track this player loading and the user landing on the page.
  useEffect(() => {
    async function doEffect(){
      trackEvent("PlayerLanding.Loaded", customizations, videoProps);
    }

    if(videoProps && customizations){
      doEffect();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoProps, customizations]);  

  return (
    <PlayerContext.Provider value={{ videoProps, playerProps }}>
      {error && <ErrorCard message={error} />}
      {!error && !playerProps && <LoadingIndicator message="Initializing player..." /> }
      {!error && playerProps && children }
    </PlayerContext.Provider>
  );
};