import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { createBrowserHistory } from 'history';

///======
/// Creates and sets up the AppInsights tracker
export const createAppInsights = (aiKey: string) => {

  const browserHistory = createBrowserHistory({ basename: '' });
  const reactPlugin = new ReactPlugin();

  const appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: aiKey,
      //endpointUrl: "https://personicom-nt-analytics-gateway.azure-api.net/track",
      maxBatchInterval: 0,
      disableFetchTracking: true,
      autoTrackPageVisitTime: false,    
      disableAjaxTracking: true,
      disableCorrelationHeaders: true,
      disableCookiesUsage: true,
      extensions: [reactPlugin],
      extensionConfig: {
        [reactPlugin.identifier]: { history: browserHistory },
      },
    },
  });

  return appInsights;
};